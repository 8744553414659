import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Localize from "react-intl-universal";
import { TextArea, Input, Form, Checkbox, Segment, Label, Tab, Menu } from "semantic-ui-react";
import ContactFormAddress from "./ContactFormAddress";
import ContactFormContactPersons from "./ContactFormContactPersons";
import * as constant from "../Constants.js";
import * as validatorConstants from "../../Utils/Constant";
import styles from "./style/ContactFormCompany.module.css";
import InvoiceNotifiers from "./InvoiceNotifiers";
import AddParentAndExportOptions from "./AddParentAndExportOptions";
import useValidator from "../../Utils/useValidator";
import {
  partnerCompanySchema,
  addressSchema,
  contactPersonSchema,
  contactAccountSchema,
  invoiceReceipmentSchema
} from "../../Utils/PartnersSchema";
import useHandleOnChange from "../../CustomHooks/useHandleOnChange";
import InvoiceTemplate from "./InvoiceTemplate";
import useTypes from "../../CustomHooks/useTypes";
import { get } from "lodash";

const ContactFormCompany = forwardRef((props, ref) => {
  const { documentDirection, mode, setEditedFields, editedFields, contact, setContact } = props;

  const [handleOnChange, handleOnChangeList, deleteItemFromList, addItemToList] = useHandleOnChange(
    contact,
    setContact
  );

  const { documentTypes, documentTypesLoading, emailTypes, emailTypesLoading } = useTypes();

  const checkEditedFields = field => {
    setEditedFields(previousState => {
      let newArr = [...previousState];
      !newArr.includes(field) && newArr.push(field);
      return newArr;
    });
  };

  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (contact && contact.note ? contact.note.length : 0)
  });

  // validator for form
  const [parameters, isFormValid, validateOnSave, validateField] = useValidator(
    partnerCompanySchema,
    "object",
    "always"
  );

  // validator for company address
  const [
    parametersAddress,
    isAddressValid,
    validateOnSaveAddress,
    validateFieldAddress
  ] = useValidator(addressSchema, "object", "always");

  // validator for billing address
  const [
    parametersBillingAddress,
    isBillingAddressValid,
    validateOnSaveBillingAddress,
    validateFieldBillingAddress
  ] = useValidator(addressSchema, "object", "ifAnyFilled");

  // validator for shipping address
  const [
    parametersShippingAddress,
    isShippingAddressValid,
    validateOnSaveShippingAddress,
    validateFieldShippingAddress
  ] = useValidator(addressSchema, "object", "ifAnyFilled");

  // validator for contact persons
  const [
    parametersContacts,
    areContactsValid,
    ,
    ,
    ,
    validateContactListOnSave,
    validateContactsListField,
    addContactInValidator,
    removeContactFromValidator
  ] = useValidator(contactPersonSchema, "list", "always");

  // validator for notifiers
  const [
    parametersRecipientsList,
    areRecipientsValid,
    ,
    ,
    ,
    validateRecipientsListOnSave,
    validateRecipientListField,
    addNotifierInValidator,
    removeNotifierFromValidator
  ] = useValidator(invoiceReceipmentSchema, "list", "always");

  // validator for accounts
  const [, , , , , validateAccountsListOnSave] = useValidator(
    contactAccountSchema,
    "list",
    "always"
  );

  // this function examines the customer checkbox in two cases, creating from documents module and creating and updating from partner module
  // the first conditions examines when a partner is created from a document module
  // the second conditions examines when a partner is editing from a partner module
  const getIsCustomerCheckBoxValue = () => {
    if (documentDirection && documentDirection === "outgoing") {
      return true;
    } else if (mode === constant.panelMode.edit) {
      return contact.isCustomer;
    } else {
      return false;
    }
  };

  const getIsSupplierCheckBoxValue = () => {
    return documentDirection
      ? documentDirection === "outgoing"
        ? false
        : true
      : contact && contact.isSupplier
      ? contact.isSupplier
      : false;
  };

  const [isCustomerValue, setIsCustomerValue] = useState(getIsCustomerCheckBoxValue());
  const [isSupplierValue, setIsSupplierValue] = useState(getIsSupplierCheckBoxValue());

  useEffect(() => {
    if (documentDirection) {
      let newContact;
      if (documentDirection === "incoming") {
        newContact = { ...contact, isSupplier: true };
      } else {
        newContact = { ...contact, isCustomer: true };
      }
      setContact(newContact);
    }
    if (mode === "edit") {
      validateOnSave(contact);
      validateRecipientsListOnSave(contact.recipients);
      validateOnSaveAddress(contact.address);
      validateOnSaveBillingAddress(contact.billingAddress);
      validateOnSaveShippingAddress(contact.shipToAddress);
      validateContactListOnSave(contact.contacts);
      validateAccountsListOnSave(contact.accounts);
    }
  }, []);

  const handleFieldChange = (e, data) => {
    switch (data.name) {
      case "isCustomer":
        setIsCustomerValue(data.checked);
        handleOnChange(data.name, data.checked);
        break;
      case "isSupplier":
        setIsSupplierValue(data.checked);
        handleOnChange(data.name, data.checked);
        break;
      case "verified":
        handleOnChange(data.name, data.checked);
        break;
      default:
        handleOnChange(data.name, data.value);
    }

    if (data.value) {
      const validationOnField = parameters[data.name];
      if (validationOnField) {
        validateField(data.name, data.value);
      }
    }

    if (e && e.target && e.target.name === "note") {
      let inputChar = e.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }

    if (data.name && !editedFields.includes(data.name)) {
      checkEditedFields(data.name);
    }
  };

  const handleOnBlur = (e, data) => {
    if (
      e &&
      e.target &&
      e.target.name in validatorConstants.formFieldsNewCompany &&
      e.target.value
    ) {
      validateField(e.target.name, e.target.value);
    }
  };

  useImperativeHandle(ref, () => ({
    validateFormOnSave(company) {
      let validGeneralPart = validateOnSave(company);
      let validNotifiers = validateRecipientsListOnSave(company.recipients);
      let validAddress = validateOnSaveAddress(company.address);
      let validBillingAddress = validateOnSaveBillingAddress(company.billingAddress);
      let validShippingAddress = validateOnSaveShippingAddress(company.shipToAddress);
      let validContactPerson = validateContactListOnSave(company.contacts);
      let validAccount = validateAccountsListOnSave(company.accounts);

      validGeneralPart =
        validGeneralPart &&
        validAccount &&
        validAddress &&
        validBillingAddress &&
        validShippingAddress &&
        validContactPerson &&
        validNotifiers;

      return validGeneralPart;
    }
  }));

  const errorBorder = "3px red solid";
  const noRadiusErrorBorder = {
    border: "3px red solid",
    borderRadius: "0px"
  };

  return (
    <div className={styles.divided_list}>
      <div className="item add_company_name">
        <div className="content">
          <Tab
            className={styles.tab_panel}
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: (
                  <Menu.Item
                    key={0}
                    className={`${styles.tab_title} ${
                      !isFormValid ||
                      !isAddressValid ||
                      !isBillingAddressValid ||
                      !isShippingAddressValid
                        ? styles.error_border
                        : ""
                    }`}
                  >
                    {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL")}
                  </Menu.Item>
                ),
                render: () => (
                  <Form size="tiny" className={styles.form}>
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>
                          {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL_INFORMATION")}
                        </strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <Form.Field required>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.COMPANY_NAME")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="name"
                            style={{
                              border: parameters.name ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.COMPANY_NAME")}
                            value={contact && contact.name ? contact.name : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.name && (
                            <Label basic color="red" pointing content={parameters.name} />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.VATIN")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="vatin"
                            style={{
                              border: parameters.vatin ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.VATIN")}
                            value={contact && contact.vatin ? contact.vatin : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.vatin && (
                            <Label basic color="red" pointing content={parameters.vatin} />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.TAX_NUMBER")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="taxNumber"
                            style={{
                              border: parameters.taxNumber ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.TAX_NUMBER")}
                            value={contact && contact.taxNumber ? contact.taxNumber : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.taxNumber && (
                            <Label basic color="red" pointing content={parameters.taxNumber} />
                          )}
                        </Form.Field>
                        <Form.Field className={styles.note_field}>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.NOTE")}</label>
                          <TextArea
                            name="note"
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.NOTE_PLACEHOLDER")}
                            value={contact && contact.note ? contact.note : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                            maxLength={255}
                            style={parameters.note ? { ...noRadiusErrorBorder } : null}
                          />
                          <p className={styles.counter_note}>
                            {charCounter.charsLeft}
                            {"/255"}
                          </p>
                        </Form.Field>
                        {parameters.note && (
                          <Label basic color="red" pointing content={parameters.note} />
                        )}
                      </Segment>
                    </Segment.Group>

                    {/* Verified */}
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>{Localize.get("CONTACTS.DETAILS.FORM.VERIFIED_PARTNER")}</strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <Form.Field>
                          <Checkbox
                            autoComplete={constant.autoComplete.off}
                            className={styles.checkbox_resize}
                            name="verified"
                            checked={get(contact, "verified")}
                            label={Localize.get("CONTACTS.DETAILS.FORM.PARTNER_IS_VERIFIED")}
                            onChange={handleFieldChange}
                          />
                        </Form.Field>
                      </Segment>
                    </Segment.Group>
                    {/* Customer */}
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>
                          {Localize.get("CONTACTS.NEWCONTACT.FORM.CUSTOMER_INFORMATION")}
                        </strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <Form.Field>
                          <Checkbox
                            autoComplete={constant.autoComplete.off}
                            className={styles.checkbox_resize}
                            name="isCustomer"
                            checked={isCustomerValue}
                            label={Localize.get("CONTACTS.NEWCONTACT.FORM.IS_CUSTOMER_LABEL")}
                            onChange={handleFieldChange}
                            disabled={
                              (documentDirection && documentDirection === "outgoing") ||
                              (contact &&
                                contact.actions &&
                                contact.actions.editRules &&
                                !contact.actions.editRules.editCustomer)
                            }
                          />
                        </Form.Field>
                        {contact && contact.isCustomer && (
                          <Form.Field>
                            {Localize.get("CONTACTS.NEWCONTACT.FORM.CUSTOMER_NUMBER_LABEL")}
                          </Form.Field>
                        )}
                      </Segment>
                    </Segment.Group>
                    {/* Supplier */}
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>
                          {Localize.get("CONTACTS.NEWCONTACT.FORM.SUPPLIER_INFORMATION")}
                        </strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <Form.Field>
                          <Checkbox
                            autoComplete={constant.autoComplete.off}
                            className={styles.checkbox_resize}
                            name="isSupplier"
                            checked={isSupplierValue}
                            label={Localize.get("CONTACTS.NEWCONTACT.FORM.IS_SUPPLIER_LABEL")}
                            onChange={handleFieldChange}
                            disabled={
                              (documentDirection && documentDirection === "incoming") ||
                              (contact &&
                                contact.actions &&
                                contact.actions.editRules &&
                                !contact.actions.editRules.editSupplier)
                            }
                          />
                        </Form.Field>
                        {contact && contact.isSupplier && (
                          <Form.Field>
                            {Localize.get("CONTACTS.NEWCONTACT.FORM.SUPPLIER_NUMBER_LABEL")}
                          </Form.Field>
                        )}
                      </Segment>
                    </Segment.Group>
                    {/* Communication */}
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.COMMUNICATION")}</strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="phone"
                            style={{
                              border: parameters.phone ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE_PLACEHOLDER")}
                            value={contact && contact.phone ? contact.phone : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.phone && (
                            <Label basic color="red" pointing content={parameters.phone} />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.FAX")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="fax"
                            style={{
                              border: parameters.fax ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.FAX_PLACEHOLDER")}
                            value={!contact.fax ? "" : contact.fax}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.fax && (
                            <Label basic color="red" pointing content={parameters.fax} />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="email"
                            style={{
                              border: parameters.email ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL_PLACEHOLDER")}
                            value={contact && contact.email ? contact.email : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.email && (
                            <Label basic color="red" pointing content={parameters.email} />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.WEB")}</label>
                          <Input
                            autoComplete={constant.autoComplete.off}
                            name="url"
                            style={{
                              border: parameters.url ? errorBorder : ""
                            }}
                            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.WEB_PLACEHOLDER")}
                            value={contact && contact.url ? contact.url : ""}
                            onChange={handleFieldChange}
                            onBlur={handleOnBlur}
                          />
                          {parameters.url && (
                            <Label basic color="red" pointing content={parameters.url} />
                          )}
                        </Form.Field>
                      </Segment>
                    </Segment.Group>
                    {/* Addresses */}
                    <Segment className={styles.title}>
                      <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESSES")}</strong>
                    </Segment>
                    <ContactFormAddress
                      key={1}
                      name="address"
                      title={Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESS")}
                      address={contact.address}
                      onChange={handleFieldChange}
                      open={true}
                      parameters={parametersAddress}
                      validateField={validateFieldAddress}
                    />
                    {!contact.asBillingAddress && (
                      <ContactFormAddress
                        key={2}
                        name="billingAddress"
                        title={Localize.get("CONTACTS.NEWCONTACT.FORM.BILLINGADDRESS")}
                        address={contact.billingAddress}
                        onChange={handleFieldChange}
                        open={false}
                        parameters={parametersBillingAddress}
                        validateField={validateFieldBillingAddress}
                      />
                    )}
                    {!contact.asShippingAddress && (
                      <ContactFormAddress
                        key={3}
                        name="shipToAddress"
                        title={Localize.get("CONTACTS.NEWCONTACT.FORM.SHIPTOADDRESS")}
                        address={contact.shipToAddress}
                        onChange={handleFieldChange}
                        open={false}
                        parameters={parametersShippingAddress}
                        validateField={validateFieldShippingAddress}
                      />
                    )}
                  </Form>
                )
              },
              {
                menuItem: (
                  <Menu.Item
                    key={1}
                    className={`${styles.tab_title} ${
                      !areContactsValid ? styles.error_border : ""
                    }`}
                  >
                    {Localize.get("CONTACTS.NEWCONTACT.FORM.CONTACT_PERSONS_TAB")}
                  </Menu.Item>
                ),
                render: () => (
                  <Form size="tiny" className={styles.form}>
                    <ContactFormContactPersons
                      name="contactPersons"
                      title={Localize.get("CONTACTS.NEWCONTACT.FORM.CONTACT_PERSONS_TITLE")}
                      contacts={contact.contacts}
                      parametersContacts={parametersContacts}
                      validateContactsListField={validateContactsListField}
                      handleOnChangeList={handleOnChangeList}
                      deleteItemFromList={deleteItemFromList}
                      addItemToList={addItemToList}
                      checkEditedFields={checkEditedFields}
                      addContactInValidator={addContactInValidator}
                      removeContactFromValidator={removeContactFromValidator}
                    />
                  </Form>
                )
              },
              {
                menuItem: (
                  <Menu.Item
                    key={3}
                    className={`${styles.tab_title} ${
                      !areRecipientsValid ? styles.error_border : ""
                    }`}
                  >
                    {Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICING_SETTINGS")}
                  </Menu.Item>
                ),
                render: () => (
                  <Form size="tiny" className={styles.form}>
                    <Segment.Group>
                      <Segment className={styles.title}>
                        <strong>
                          {Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICING_SETTINGS")}
                        </strong>
                      </Segment>
                      <Segment className={styles.form_lines}>
                        <InvoiceNotifiers
                          notifiers={contact.recipients}
                          parametersRecipients={parametersRecipientsList}
                          checkEditedFields={checkEditedFields}
                          validateRecipientListField={validateRecipientListField}
                          deleteItemFromList={deleteItemFromList}
                          addItemToList={addItemToList}
                          handleOnChangeList={handleOnChangeList}
                          sendInvoiceToParent={contact.sendInvoiceToParent}
                          addNotifierInValidator={addNotifierInValidator}
                          removeNotifierFromValidator={removeNotifierFromValidator}
                        />
                        <InvoiceTemplate
                          types={documentTypes}
                          header={"CONTACTS.NEWCONTACT.FORM.INVOICE_TEMPLATES"}
                          handleOnChange={handleOnChange}
                          // checkEditedFields={checkEditedFields}
                          // editedFields={editedFields}
                          defaultTemplates={contact.defaultTemplates}
                          loading={documentTypesLoading}
                          sendInvoiceToParent={contact.sendInvoiceToParent}
                          contactId={contact.id}
                        />
                        <InvoiceTemplate
                          types={emailTypes}
                          header={"PROPERTIES.TYPES.Email templates"}
                          handleOnChange={handleOnChange}
                          // checkEditedFields={checkEditedFields}
                          // editedFields={editedFields}
                          defaultTemplates={contact.defaultTemplates}
                          loading={emailTypesLoading}
                          sendInvoiceToParent={contact.sendInvoiceToParent}
                          contactId={contact.id}
                        />
                        <AddParentAndExportOptions
                          setContact={setContact}
                          setEditedFields={setEditedFields}
                          contact={contact}
                          documentTypes={documentTypes}
                        />
                      </Segment>
                    </Segment.Group>
                  </Form>
                )
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
});

export default ContactFormCompany;
