import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  addDocumentToDate(toDate) {
    this.parameter.toDate = toDate;
    return this;
  }
  addDocumentFromDate(fromDate) {
    this.parameter.fromDate = fromDate;
    return this;
  }
  addAssignmentFilter() {
    this.parameter.assignment = "unlinked";
    return this;
  }
  addDocumentDirectionIncoming() {
    this.parameter.direction = constant.DOCUMENT.direction.incoming;
    return this;
  }
  addDocumentDirectionOutgoing() {
    this.parameter.direction = constant.DOCUMENT.direction.outgoing;
    return this;
  }
  clearDocumentDirection() {
    delete this.parameter.direction;
    return this;
  }
  addDocumentTypeInquiry() {
    this.parameter.type = constant.DOCUMENT.type.inquiry;
    return this;
  }
  addDocumentTypeOffer() {
    this.parameter.type = constant.DOCUMENT.type.offer;
    return this;
  }
  addDocumentTypeOrder() {
    this.parameter.type = constant.DOCUMENT.type.order;
    return this;
  }
  addDocumentTypeReceipt() {
    this.parameter.type = constant.DOCUMENT.type.receipt;
    return this;
  }
  addDocumentTypeDelivery() {
    this.parameter.type = constant.DOCUMENT.type.delivery;
    return this;
  }
  addDocumentTypeInvoice() {
    this.parameter.type = constant.DOCUMENT.type.invoice;
    return this;
  }
  addDocumentTypeInvoiceCorrection() {
    this.parameter.type = constant.DOCUMENT.type.correction;
    return this;
  }
  addDocumentTypeOther() {
    this.parameter.type = constant.DOCUMENT.type.other;
    return this;
  }
  addDocumentTypeinvoiceAndCorrection() {
    this.parameter.type = constant.DOCUMENT.type.invoice
      .concat(",")
      .concat(constant.DOCUMENT.type.correction);
    return this;
  }
  clearDocumentType() {
    delete this.parameter.type;
    return this;
  }
  addDocumentVerificationStatusVerified() {
    this.parameter.verification = constant.DOCUMENT.verification.verified;
    return this;
  }
  addDocumentVerificationUnverified() {
    this.parameter.verification = constant.DOCUMENT.verification.unverified;
    return this;
  }
  addDocumentVerificationStatusDisapproved() {
    this.parameter.verification = constant.DOCUMENT.verification.disapproved;
    return this;
  }
  addDocumentPaymentStatusApprove() {
    this.parameter.approval = constant.DOCUMENT.payment.approved;
    return this;
  }
  addDocumentPaymentStatusDecline() {
    this.parameter.approval = constant.DOCUMENT.payment.declined;
    return this;
  }
  addDocumentPaymentStatusUnresolved() {
    this.parameter.approval = constant.DOCUMENT.payment.unresolved;
    return this;
  }
  addDocumentDueStatusOverdue(overdueRange) {
    this.parameter.due = overdueRange;
    return this;
  }
  addDocumentDueStatusToday(todayRange) {
    this.parameter.due = todayRange;
    return this;
  }
  addDocumentDueStatusThisWeek(thisWeekRange) {
    this.parameter.due = thisWeekRange;
    return this;
  }
  addDocumentDueStatusNextWeek(nextWeekRange) {
    this.parameter.due = nextWeekRange;
    return this;
  }
  addDocumentDueStatusThisMonth(thisMonthRange) {
    this.parameter.due = thisMonthRange;
    return this;
  }
  addDocumentArchiveStatusArchived() {
    this.parameter.archived = constant.DOCUMENT.archived.archived;
    return this;
  }
  clearDueFilter() {
    delete this.parameter.due;
    return this;
  }
  addDocumentEarlyDueStatusOverdue(overdueRange) {
    this.parameter.savingsDue = overdueRange;
    return this;
  }
  addDocumentEarlyDueStatusToday(todayRange) {
    this.parameter.savingsDue = todayRange;
    return this;
  }
  addDocumentEarlyDueStatusThisWeek(thisWeekRange) {
    this.parameter.savingsDue = thisWeekRange;
    return this;
  }
  addDocumentEarlyDueStatusNextWeek(nextWeekRange) {
    this.parameter.savingsDue = nextWeekRange;
    return this;
  }
  addDocumentEarlyDueStatusThisMonth(thisMonthRange) {
    this.parameter.savingsDue = thisMonthRange;
    return this;
  }
  clearEarlyDueFilter() {
    delete this.parameter.savingsDue;
    return this;
  }
  clearApprovalStatus() {
    delete this.parameter.verification;
    return this;
  }
  addSearchParam(value) {
    this.parameter.search = value;
    return this;
  }
  clearSearchParam() {
    delete this.parameter.search;
    return this;
  }
  addSearchMatchParam(value) {
    this.parameter.match = value;
    return this;
  }
  clearSearchMatchParam() {
    delete this.parameter.match;
    return this;
  }
  addCustomColumnSearch(column, value) {
    this.parameter[column] = value;
    return this;
  }
  addDocumentNumber(value) {
    this.parameter.number = value;
    return this;
  }
  clearCustomColumnSearch(value) {
    delete this.parameter[value];
    return this;
  }
  addSortColumnParam(value) {
    this.parameter.sortBy = value;
    return this;
  }
  clearSortColumnParam(value) {
    delete this.parameter.sortBy;
    return this;
  }
  addSortDirectionParam(value) {
    this.parameter.sortDirection = value;
    return this;
  }
  clearSortDirectionParam(value) {
    delete this.parameter.sortDirection;
    return this;
  }
  addPaymentStatusPaid() {
    const paidStatuses = [
      constant.DOCUMENT.paymentStatus.partialPaid,
      constant.DOCUMENT.paymentStatus.paid
    ];
    this.parameter.payment = paidStatuses.join(",");
    return this;
  }
  addSentToDatev(value) {
    this.parameter.sentToDatev = value;
    return this;
  }
  documentWithoutParent() {
    this.parameter.parentDocument = "null";
  }
  documentWithPartner(id) {
    this.parameter.partnerId = id;
  }

  progressStatus(value) {
    this.parameter.progress = value;
    return this;
  }

  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.documents
    };
    this.query = new CustomQuery.Builder();
  }
}
