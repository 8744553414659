import React, { useState, useEffect, useReducer, useRef } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormField,
  TextArea,
  Icon,
  Divider,
  Header,
  Modal,
  Loader,
  Dimmer,
  Grid,
  Popup,
  Tab,
  Menu
} from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as RestService from "../../RestService";
import * as constant from "../Constants";
import * as visabilityConstants from "../VisabilityConstants";
import DocNewItemTable from "./DocumentFormComponents/DocNewItemTable";
import AddNewPartner from "./DocumentFormComponents/AddNewPartner";
import ChangePropertiesWarningModal from "./DocumentFormComponents/ChangePropertiesWarningModal";
import WarningOkDialog from "../../Dialogs/WarningOkDialog";
import GlobalInfoDialog from "../../Dialogs/GlobalInfoDialog";
import FillMandatoryDataWarningNotification from "../../Notifications/FillMandatoryDataWarningModal";
import ReferedInvoiceList from "./ReferedInvoiceList";
import * as notificationConstants from "../../Notifications/Constants";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";
import { createNewCompanyObject, createNewPersonObject } from "../../Contacts/Util";
import union from "lodash.union";
import { formatAmount, documentTypeOptions } from "../Util";
import styles from "./style/DocumentForm.module.css";
import GlobalDropdown from "./GlobalDropdown";
import GlobalDatedown from "./GlobalDatedown";
import TaxSubtotals from "./TaxSubtotals";
import DocumentTotal from "./DocumentTotal";
import GlobalModal from "../../Components/Panels/GlobalModal";
import ReferedPartner from "./ReferedPartner";
import { createGetAllService } from "../../Components/Utils/GlobalUtil";
import useDocumentValidator from "./useDocumentValidator";

// custom hook for recalculate
import useRecalculate from "./useRacalcuate";

const DocumentForm = props => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /*Alert if clicked on outside of element*/
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref.current === docDateDropdown.current) {
            setCreationDateMenuOpen(false);
          }
          if (ref.current === deliveryDateDropdown.current) {
            setDeliveryDateMenuOpen(false);
          }
          if (ref.current === dueDateDropdown.current) {
            setDueDateMenuOpen(false);
          }
        }
      }

      // Bind the event listener
      window.document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        window.document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const docDateDropdown = useRef(null);
  const deliveryDateDropdown = useRef(null);
  const dueDateDropdown = useRef(null);

  useOutsideAlerter(docDateDropdown);
  useOutsideAlerter(deliveryDateDropdown);
  useOutsideAlerter(dueDateDropdown);
  const expenseOptions = [
    {
      key: 0,
      value: "incoming",
      text: Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")
    },
    {
      key: 1,
      value: "outgoing",
      text: Localize.get("TRANSACTIONS.DIRECTION.REVENUE")
    }
  ];

  const currencyOptions = [
    {
      key: 0,
      value: "EUR",
      icon: "euro sign",
      text: "EUR"
    },
    {
      key: 1,
      value: "GBP",
      icon: "pound",
      text: "GBP"
    },
    {
      key: 2,
      value: "USD",
      icon: "dollar sign",
      text: "Dollar"
    }
  ];

  // TODO Props destructured are displayed below, but not all of them,
  // there are a couple of them that needs to be destructured but first, we need to change the existing names of setters
  // thumbsToSend, documentNumbers, showNotificationMessage and validator
  const {
    partnerPrivileges,
    document,
    documents,
    state,
    handler,
    isPDFPreview,
    linkedDocumentsPreview,
    documentPreview,
    isLoadingForm
  } = props;

  const checkTaxAndSubtotals = doc => {
    if (!doc.documentDiscount) doc.documentDiscount = {};
    return doc;
  };

  const {
    equalsItemsWithTaxSubtotals,
    equalsItemsWithDocumentHeader,
    equalsTaxSubtotalsWithDocumentHeader,
    checkEquality,
    recalculate,
    calculateDocumentTotalFromSubtotals
  } = useRecalculate(document === undefined ? {} : checkTaxAndSubtotals(document));

  const [documentZugfredObject, setDocumentZugfredObject] = useState(
    document === undefined ? {} : checkTaxAndSubtotals(document)
  );

  const checkVisibleFielfs = (docType, docDirection) => {
    switch (docType) {
      case "invoice":
      case "imported_invoice":
      case "annex":
        if (docDirection === "incoming") {
          return visabilityConstants.invoiceInc;
        } else {
          return visabilityConstants.invoiceOut;
        }
      case "correction":
        if (docDirection === "incoming") {
          return visabilityConstants.correctionInc;
        } else {
          return visabilityConstants.correctionRev;
        }

      case "delivery":
        return visabilityConstants.delivery;
      case "inquiry":
        return visabilityConstants.inquiry;
      case "offer":
        return visabilityConstants.offer;
      case "order":
        return visabilityConstants.offer;
      case "receipt":
        return visabilityConstants.receipt;
      default:
        return visabilityConstants.defaultDocument;
    }
  };

  const [visibleFields, setVisibleFields] = useState(
    document === undefined ? [] : checkVisibleFielfs(document.type, document.direction)
  );

  const [dokument, setDokument] = useState({
    documentDate: new Date(),
    paymentDue: new Date(),
    deliveryDate: new Date()
  });

  const [showModal, setShowModal] = useState();
  const [showPartnerInfoModal, setShowPartnerInfoModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalMessage, setWarningModalMessage] = useState("");
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);
  const [requirementsModal, setRequirementsModal] = useState(false);
  const [partnerModal, setPartnerModal] = useState(false);

  const {
    documentFormValidator,
    totalValidator,
    documentItemsValidator,
    documentSubtotalsValidator,
    subtotalsAreValid,
    validateDocumentFunc,
    validateDocumentFormField,
    validateTotalField,
    validateSubtotalField,
    validateOrderItemField,
    validateSubtotalsFunc,
    validateItemsFunc,
    totalIsValid,
    docIsValid,
    setTotalIsValid,
    setSubtotalsAreValid
  } = useDocumentValidator(documentZugfredObject);

  const updateOrderItemsValidator = newList => {
    validateItemsFunc({ ...documentZugfredObject, orderItems: newList });
  };

  const updateOrderItemsValidatorAfterDelete = (newList, currentDocument) => {
    validateItemsFunc({ ...currentDocument, orderItems: newList });
  };

  const [checkEdit, setCheckEdit] = useReducer((state, action) => {
    switch (action.type) {
      case "nonEditState":
        validateDocumentFunc(documentZugfredObject);
        return {
          ...constant.initCheckState
        };
      case "editItems":
        return {
          ...state,
          documentItems: true,
          editMode: true
        };
      case "editSubtotals":
        return {
          ...state,
          documentSubtotals: true,
          editMode: true
        };
      case "editTotals":
        return {
          ...state,
          documentTotal: true,
          editMode: true
        };
      default:
        return state;
    }
  }, constant.initCheckState);

  const [directionValue, setDirectionValue] = useState(
    document
      ? document.direction === constant.documentDirection.incoming
        ? expenseOptions[0].value
        : expenseOptions[1].value
      : expenseOptions[0].value
  );

  const [partnerValue, setPartnerValue] = useState(
    document && document.partner && document.partner.id ? document.partner : undefined
  );

  const [partnerOCRRead, setPartnerOCRRead] = useState();
  const [checkPartner, setCheckPartner] = useState(false);
  const [partnersLoading, setPartnersLoading] = useState(false);
  const [editedFields, setEditedFields] = useState([]);
  const [thumbsToSend, setThumbsToSend] = useState(props.thumbsToSend);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const [relatedDocumentState, setRelatedDocumentState] = useState(
    document && document.parentDocument && document.parentDocument.documentNumber
      ? document.parentDocument.documentNumber
      : ""
  );

  const [dueDateMenuOpen, setDueDateMenuOpen] = useState(false);
  const [deliveryDateMenuOpen, setDeliveryDateMenuOpen] = useState(false);
  const [creationDateMenuOpen, setCreationDateMenuOpen] = useState(false);
  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (document && document.description ? document.description.length : 0)
  });
  const [propertyRaisedWarning, setPropertyRaisedWarning] = useState("");

  // Warning for adding or editing order items process
  const [showNotAddedItemtWarningModal, setShowNotAddedItemtWarningModal] = useState(false);

  const [docItemMandatoryFields] = useState(false);
  const [docItemEditFormMandatoryFields] = useState(false);
  const [warningNotAddedItemModalMessage, setWarningNotAddedItemModalMessage] = useState("");
  const [showInfoModal, setShowInfoModal] = useState("");

  const callbackGetAllFinally = response => {};

  const callbackCreateSuccess = response => {
    setShowModal(false);
    props.showNotificationMessage({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.create
    });
  };

  const callbackCreateError = error => {
    setFormErrorMessage(error);
    setShowModal(true);
  };

  const errorBorder = "3px red solid";
  const noRadiusErrorBorder = { border: "3px red solid", borderRadius: "0px" };

  const [isNotificationAlive, setIsNotificationAlive] = useState(false);

  useEffect(() => {
    if (documentZugfredObject.documentDate) {
      setDokument({
        ...dokument,
        documentDate: documentZugfredObject.documentDate
      });
    }
  }, []);

  const callBackGetPartnerSuccess = res => {
    if (res.data && res.data.data && res.data.data.length > 0) {
      setDocumentZugfredObject({
        ...documentZugfredObject,
        partner: res.data.data[0]
      });
      setCheckPartner(false);
    }
  };
  const callBackGetPartnerError = res => {};
  const callBackGetPartnerFinally = res => {
    setPartnersLoading(false);
  };

  // Handle data from OCR read
  useEffect(() => {
    if (
      documentZugfredObject &&
      documentZugfredObject.partner &&
      !documentZugfredObject.partner.id &&
      documentZugfredObject.partner.name !== ""
    ) {
      setCheckPartner(true);
      setPartnerOCRRead(documentZugfredObject.partner);
      async function callForPartner() {
        const newService = createGetAllService(
          callBackGetPartnerSuccess,
          callBackGetPartnerError,
          callBackGetPartnerFinally,
          "partners"
        );
        newService.query.addCustomColumnSearch("name", documentZugfredObject.partner.name);
        //match to not work well with umlaut letters
        await newService.call();
      }

      setPartnersLoading(true);
      callForPartner();
    }
  }, [documentZugfredObject.partner]);

  useEffect(() => {
    if (document) {
      validateDocumentFunc(document);

      setDocumentZugfredObject(document);
      checkEquality(document);
    }
  }, [document]);

  useEffect(() => {
    setVisibleFields(
      checkVisibleFielfs(documentZugfredObject.documentType, documentZugfredObject.direction)
    );
  }, [documentZugfredObject.direction, documentZugfredObject.documentType]);

  const createNewPartnerServiceCall = (contact, newContactType) => {
    let partnerCreateService;
    let newObject;

    if (newContactType === "company") {
      partnerCreateService = new RestService.Company.Create.Service();
      newObject = createNewCompanyObject(contact);
    } else {
      partnerCreateService = new RestService.Person.Create.Service();
      newObject = createNewPersonObject(contact);
    }

    partnerCreateService.setPayload(newObject);
    partnerCreateService.setCallbackError(callbackCreateError);
    partnerCreateService.setCallbackSuccess(callbackCreateSuccess);
    partnerCreateService.setCallbackFinally(callbackGetAllFinally);
    partnerCreateService.call();
  };

  const handle = {
    contactFormPanel: {
      onSaveButtonClick: (contact, newContactType) => {
        if (contact) {
          createNewPartnerServiceCall(contact, newContactType);
        }
      },
      onCancelButtonClick: () => {
        setShowModal(false);
      },
      handleOpenModal: (event, data) => {
        setShowModal(true);
      }
    }
  };

  const onSaveClick = async () => {
    if (validateDocumentFunc(documentZugfredObject, true)) {
      if (thumbsToSend && thumbsToSend.length > 0) {
        editedFields.push("documents");
      }

      if (editedFields.length > 0) {
        const editedByref = union(editedFields, documentZugfredObject.editedFields);
        documentZugfredObject.editedFields = editedByref;
      }

      if (docItemMandatoryFields || docItemEditFormMandatoryFields) {
        warningOrderItemsMsgHandler();
      } else {
        handler.documentForm.onSaveButtonClick(documentZugfredObject);
      }
    } else {
      if (!isNotificationAlive) {
        setIsNotificationAlive(true);
      }
    }
  };

  const onUpdateDocumentSave = () => {
    // keep this part of code for next task regarding validation

    if (validateDocumentFunc(documentZugfredObject, true)) {
      let difference = [];
      const nonEditedDoc = documents.filter(element => element.id === document.id);
      const prevLinkedLength =
        nonEditedDoc && nonEditedDoc[0].documents ? nonEditedDoc[0].documents.length : 0;

      if (thumbsToSend.length >= prevLinkedLength) {
        difference =
          // eslint-disable-next-line eqeqeq
          prevLinkedLength != 0
            ? thumbsToSend.filter(
                thumb => !nonEditedDoc[0].documents.some(doc => doc.id === thumb.id)
              )
            : thumbsToSend;
      } else {
        difference = nonEditedDoc[0].documents.filter(
          thumb => !thumbsToSend.some(doc => doc.id === thumb.id)
        );
      }

      if (difference.length === 0) {
        if (editedFields && editedFields.includes("documents")) {
          editedFields.splice(editedFields.indexOf("documents"), 1);
        }
        if (documentZugfredObject && documentZugfredObject.editedFields) {
          documentZugfredObject.editedFields.splice(
            documentZugfredObject.editedFields.indexOf("documents"),
            1
          );
        }
      }

      if (editedFields.length > 0) {
        const editedByref = union(editedFields, documentZugfredObject.editedFields);
        documentZugfredObject.editedFields = editedByref;
      }

      if (docItemMandatoryFields || docItemEditFormMandatoryFields) {
        warningOrderItemsMsgHandler();
      } else {
        handler.documentForm.onUpdateDocumentSave(documentZugfredObject, thumbsToSend);
      }
    } else {
      if (!isNotificationAlive) {
        setIsNotificationAlive(true);
      }
    }
  };

  const onSaveNewClick = () => {
    if (editedFields.length > 0) {
      const editedByref = union(editedFields, documentZugfredObject.editedFields);
      documentZugfredObject.editedFields = editedByref;
    }

    if (docItemMandatoryFields || docItemEditFormMandatoryFields) {
      warningOrderItemsMsgHandler();
    } else {
      if (validateDocumentFunc(documentZugfredObject, true)) {
        props.handler.documentForm.onSaveAndNewButtonClick(documentZugfredObject);
      } else {
        if (!isNotificationAlive) {
          setIsNotificationAlive(true);
        }
      }
    }
  };

  const handleOnChange = (e, data) => {
    let newDocument;
    switch (data.name) {
      case "partner":
        const partner = data.value;
        setPartnerValue(partner);
        if (documentZugfredObject.parentDocument || thumbsToSend.length > 0) {
          setShowWarningModal(true);
          setPropertyRaisedWarning("partner");
          setWarningModalMessage(Localize.get("DOCUMENTS.FORM.LINKED_DOCS_DOCS_MSG"));
        } else {
          newDocument = {
            ...dokument,
            ...documentZugfredObject,
            partner: partner,
            parentDocument: null
          };

          setPartnerOCRRead({
            name: "",
            phone: "",
            email: "",
            url: ""
          });
          setCheckPartner(false);
          setDocumentZugfredObject(newDocument);
        }

        break;
      case "direction":
        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          [data.name]: data.value,
          parentDocument: null
        };
        setRelatedDocumentState("");
        setDirectionValue(
          data.value === constant.documentDirection.incoming
            ? expenseOptions[0].value
            : expenseOptions[1].value
        );
        setDocumentZugfredObject(newDocument);
        break;
      case "parentDocument":
        newDocument = {
          ...document,
          ...documentZugfredObject,
          parentDocument: data.value ? { id: Number(data.value) } : null
        };
        setDocumentZugfredObject(newDocument);
        break;
      case "amount":
        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          [data.name]: formatAmount(data.value)
        };
        setDocumentZugfredObject(newDocument);
        break;
      case "documentType":
        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          [data.name]: data.value,
          parentDocument: null
        };
        setRelatedDocumentState("");
        setDocumentZugfredObject(newDocument);
        break;
      case "documentNumber":
        let docNumber = data.value;

        if (docNumber.includes(" ")) {
          docNumber = docNumber.replace(/ /g, "");
        }
        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          [data.name]: docNumber
        };
        setDocumentZugfredObject(newDocument);
        break;
      case "currency":
        setDocumentZugfredObject(prevState => {
          return { ...prevState, [data.name]: { id: data.value } };
        });
        break;
      default:
        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          [data.name]: data.value
        };
        setDocumentZugfredObject(newDocument);
    }

    if (documentFormValidator.parameter[data.name] !== "") {
      validateDocumentFormField(data.value, data.name);
    }

    if (data.name && !editedFields.includes(data.name)) {
      const newArr = [...editedFields];
      newArr.push(data.name);
      if (
        data.name === constant.filterOptions.documentType ||
        data.name === constant.filterOptions.direction ||
        data.name === constant.filterOptions.partner
      ) {
        newArr.push(constant.filterOptions.parentDocument);
      }
      setEditedFields(newArr);
    }

    if (e && e.target && e.target.name === "description") {
      let inputChar = e.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }

    allowEditConnectedProperties(data.name, data.value);
  };

  const handleOnBlur = (e, data) => {
    let propName = data ? data.name : e.target.name;
    let propValue = documentZugfredObject[propName];

    validateDocumentFormField(propValue, propName);
  };

  const handleCreationDateClick = (day, selected, event) => {
    let d = new Date();
    let initiaYear = new Date("1900-01-01");
    let dayYear = day.getFullYear();

    if (day.setHours(0, 0, 0, 0) <= d.setHours(0, 0, 0, 0)) {
      if (initiaYear.getFullYear() === dayYear) {
        day.setFullYear(2010);
      }
      event.stopPropagation();
      setCreationDateMenuOpen(false);
      if (!editedFields.includes("documentDate")) {
        const newArr = [...editedFields];
        newArr.push("documentDate");
        setEditedFields(newArr);
      }

      validateDocumentFormField(day, "documentDate");

      setDokument({ ...dokument, documentDate: day });
      setDocumentZugfredObject({
        ...documentZugfredObject,
        documentDate: day
      });
    }
  };

  const handleDueDateClick = (day, selected, event) => {
    event.stopPropagation();
    setDueDateMenuOpen(false);

    let initiaYear = new Date("1900-01-01");
    let dayYear = day.getFullYear();

    if (initiaYear.getFullYear() === dayYear) {
      day.setFullYear(2010);
    }

    if (!editedFields.includes("paymentDue")) {
      const newArr = [...editedFields];
      newArr.push("paymentDue", "documentDiscount");
      setEditedFields(newArr);
    }

    validateDocumentFormField(day, "paymentDue");

    setDokument({ ...dokument, paymentDue: day });
    setDocumentZugfredObject({
      ...documentZugfredObject,
      documentDiscount: {
        ...documentZugfredObject.documentDiscount,
        earlyPayDate:
          documentZugfredObject.documentDiscount.earlyPayDate === null
            ? null
            : day < new Date(documentZugfredObject.documentDiscount.earlyPayDate)
            ? day
            : documentZugfredObject.documentDiscount.earlyPayDate
      },
      paymentDue: day
    });
  };

  const handleDeliveryDateClick = (day, selected, event) => {
    event.stopPropagation();
    setDeliveryDateMenuOpen(false);

    let initiaYear = new Date("1900-01-01");
    let dayYear = day.getFullYear();

    if (initiaYear.getFullYear() === dayYear) {
      day.setFullYear(2010);
    }

    if (!editedFields.includes("deliveryDate")) {
      const newArr = [...editedFields];
      newArr.push("deliveryDate");
      setEditedFields(newArr);
    }

    setDokument({ ...dokument, deliveryDate: day });
    setDocumentZugfredObject({
      ...documentZugfredObject,
      deliveryDate: day
    });
  };

  const deleteItem = itemIndex => {
    // eslint-disable-next-line array-callback-return
    let tempListOfItems = documentZugfredObject.orderItems.filter(
      // eslint-disable-next-line array-callback-return
      (item, index) => {
        if (index !== itemIndex) {
          return item;
        }
      }
    );

    updateOrderItemsValidatorAfterDelete(tempListOfItems, documentZugfredObject);

    setDocumentZugfredObject({
      ...documentZugfredObject,
      orderItems: tempListOfItems
    });

    if (!editedFields.includes("orderItems")) {
      const newArr = [...editedFields];
      newArr.push("orderItems");
      setEditedFields(newArr);
    }
  };

  const openParnerMsg = () => {
    setShowPartnerInfoModal(true);
  };

  const closeParnerMsg = () => {
    setShowPartnerInfoModal(false);
  };

  const registertedPartnerData = () => {
    let partnerDataVar = null;
    if (document && document.partner) {
      partnerDataVar = (
        <Form>
          <Modal size="small" closeIcon onClose={closeParnerMsg} open={showPartnerInfoModal}>
            <Modal.Header>
              {Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_OCR_ADD_NEW_PARTNER")}
            </Modal.Header>
            <Modal.Content>
              <p>{Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_OCR_MESSAGE1")}</p>
              <p>{Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_OCR_MESSAGE2")}</p>

              <Divider horizontal className={styles.divider_margin}>
                <Header as="h4">
                  <Icon name="check" className={styles.check_icon} />
                  {Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_OCR_HEADER")}
                </Header>
              </Divider>

              <Form>
                <Form.Group unstackable widths={2}>
                  <Form.Input
                    color="brown"
                    autoComplete={constant.autoComplete.none}
                    label={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_NAME")}
                    placeholder={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_NAME")}
                    value={document.partner.name}
                  />
                </Form.Group>
                <Form.Group unstackable widths={2}>
                  <Form.Input
                    autoComplete={constant.autoComplete.none}
                    label={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_PHONE")}
                    placeholder={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_PHONE")}
                    value={document.partner.phone}
                  />
                  <Form.Input
                    autoComplete={constant.autoComplete.none}
                    label={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_MOBILE")}
                    placeholder={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_MOBILE")}
                    value={document.partner.mobile}
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Input
                    autoComplete={constant.autoComplete.none}
                    label={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_EMAIL")}
                    placeholder={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_EMAIL")}
                    value={document.partner.email}
                  />
                  <Form.Input
                    autoComplete={constant.autoComplete.none}
                    label={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_WEB")}
                    placeholder={Localize.get("DOCUMENTS.MODAL.MODAL_PARTNER_WEB")}
                    value={document.partner.web}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
          </Modal>
        </Form>
      );
    }
    return partnerDataVar;
  };

  const getDocumentDate = () => {
    let docDate = "";
    if (documentZugfredObject.documentDate) {
      docDate = new Date(documentZugfredObject.documentDate).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
    return docDate;
  };

  const getPaymentDueDate = () => {
    let paymentDate = "";
    if (documentZugfredObject.paymentDue) {
      paymentDate = new Date(documentZugfredObject.paymentDue).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
    return paymentDate;
  };

  const getDeliveryDate = () => {
    let deliveryDate = "";
    if (documentZugfredObject.deliveryDate) {
      deliveryDate = new Date(documentZugfredObject.deliveryDate).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
    return deliveryDate;
  };

  const setDocumentNumberValue = () => {
    let documentNumberValue;
    if (documentZugfredObject && documentZugfredObject.documentNumber) {
      documentNumberValue = documentZugfredObject.documentNumber;
    } else {
      documentNumberValue = "";
    }
    return documentNumberValue;
  };

  const allowEditConnectedProperties = (editedProperty, propertyValue) => {
    // partner depends on direction
    switch (editedProperty) {
      case "direction":
        if (documentZugfredObject.partner) {
          if (propertyValue === constant.documentDirection.incoming) {
            if (
              documentZugfredObject.partner.customer &&
              documentZugfredObject.partner.supplier === null
            ) {
              if (
                (thumbsToSend && thumbsToSend.length > 0) ||
                documentZugfredObject.parentDocument
              ) {
                setWarningModalMessage(Localize.get("DOCUMENTS.FORM.PARTNER_AND_LINKED_DOCS_MSG"));
              } else {
                setWarningModalMessage(Localize.get("DOCUMENTS.FORM.PARTNER_DOCS_MSG"));
              }
              setShowWarningModal(true);
              setPropertyRaisedWarning("direction");
            }
          } else {
            if (
              documentZugfredObject.partner.supplier &&
              documentZugfredObject.partner.customer === null
            ) {
              if (
                (thumbsToSend && thumbsToSend.length > 0) ||
                documentZugfredObject.parentDocument
              ) {
                setWarningModalMessage(Localize.get("DOCUMENTS.FORM.PARTNER_AND_LINKED_DOCS_MSG"));
              } else {
                setWarningModalMessage(Localize.get("DOCUMENTS.FORM.PARTNER_DOCS_MSG"));
              }
              setShowWarningModal(true);
              setPropertyRaisedWarning("direction");
            }
          }
        }
        break;

      default:
        break;
    }

    //linked documents dedpend on selected partner
  };

  const closeWarningModal = () => {
    let newDocument;
    switch (propertyRaisedWarning) {
      case "direction":
        newDocument = {
          ...documentZugfredObject,
          direction: document.direction
        };

        setDirectionValue(
          document.direction === constant.documentDirection.incoming
            ? expenseOptions[0].value
            : expenseOptions[1].value
        );

        break;
      case "partner":
        newDocument = {
          ...documentZugfredObject,
          partner: document.partner
        };

        break;
      default:
        break;
    }
    setDocumentZugfredObject(newDocument);
    setShowWarningModal(false);
    setPropertyRaisedWarning("");
  };

  // ITEMS SUBTOTAL HEADER //
  const itemsHandler = items => {
    setDocumentZugfredObject({
      ...documentZugfredObject,
      orderItems: items
    });

    if (!editedFields.includes("orderItems")) {
      const newArr = [...editedFields];
      newArr.push("orderItems");
      setEditedFields(newArr);
    }

    checkEquality({
      ...documentZugfredObject,
      orderItems: items
    });

    setCheckEdit({ type: "nonEditState" });
    validateDocumentFunc({
      ...documentZugfredObject,
      orderItems: items
    });
  };

  const subtotalsHandler = subtotals => {
    setDocumentZugfredObject({
      ...documentZugfredObject,
      taxSubtotals: subtotals
    });

    if (!editedFields.includes("taxSubtotals")) {
      const newArr = [...editedFields];
      newArr.push("taxSubtotals");
      setEditedFields(newArr);
    }

    checkEquality({
      ...documentZugfredObject,
      taxSubtotals: subtotals
    });

    setCheckEdit({ type: "nonEditState" });
    validateDocumentFunc({
      ...documentZugfredObject,
      taxSubtotals: subtotals
    });
  };

  const documentTotalHandler = documentTotal => {
    setDocumentZugfredObject({
      ...documentZugfredObject,
      ...documentTotal
    });

    checkEquality({
      ...documentZugfredObject,
      ...documentTotal
    });

    setCheckEdit({ type: "nonEditState" });
  };

  const recalculatePerSubtotal = () => {
    const newHeader = calculateDocumentTotalFromSubtotals(documentZugfredObject);
    const newDoc = { ...documentZugfredObject, ...newHeader };

    setDocumentZugfredObject(newDoc);
    checkEquality(newDoc);

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount",
      "taxSubtotals"
    ];
    setEditedFields(newArr);
  };

  const recalculateClicked = () => {
    const newDoc = recalculate(documentZugfredObject);

    validateDocumentFunc(newDoc);

    setDocumentZugfredObject(newDoc);
    checkEquality(newDoc);

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount",
      "taxSubtotals"
    ];
    setEditedFields(newArr);
  };
  ///////////////////////////

  const confirmChangeHandler = () => {
    let newDocument;

    switch (propertyRaisedWarning) {
      case "direction":
        newDocument = {
          ...documentZugfredObject,
          partner: null,
          documents: null,
          parentDocument: null
        };
        setDirectionValue(
          documentZugfredObject.direction === constant.documentDirection.incoming
            ? expenseOptions[0].value
            : expenseOptions[1].value
        );
        setDocumentZugfredObject(newDocument);

        if (!editedFields.includes("partner")) {
          editedFields.push("partner");
        }
        if (!editedFields.includes("parentDocument")) {
          editedFields.push("parentDocument");
        }

        setPartnerValue(undefined);

        if (thumbsToSend && thumbsToSend.length > 0) {
          setThumbsToSend([]);
          handler.documentForm.clearThumbs();
        }
        break;
      case "partner":
        const partner = checkPartnerDirection(documentZugfredObject, partnerValue);

        newDocument = {
          ...dokument,
          ...documentZugfredObject,
          partner: partner,
          parentDocument: null
        };
        setRelatedDocumentState("");
        setPartnerOCRRead({
          name: "",
          phone: "",
          email: "",
          url: ""
        });

        setCheckPartner(false);
        setDocumentZugfredObject(newDocument);

        break;
      default:
        break;
    }

    setShowWarningModal(false);
    setPropertyRaisedWarning("");
  };

  const checkPartnerDirection = (currentDocumentValue, currentPartnerValue) => {
    if (currentPartnerValue && currentPartnerValue.id) {
      return currentPartnerValue;
    } else {
      return currentDocumentValue.partner;
    }
  };

  const getWarningDialog = () => {
    let dialog = null;
    if (showWarningModal) {
      dialog = (
        <ChangePropertiesWarningModal
          showModal={showWarningModal}
          handleCloseModal={closeWarningModal}
          confirmChangeHandler={confirmChangeHandler}
          warningMessage={warningModalMessage}
        />
      );
    }
    return dialog;
  };

  // warnings for order items
  const confirmNotAddedItem = () => {
    setShowNotAddedItemtWarningModal(false);
  };

  const getNotAddedItemWarningDialog = () => {
    let dialog = null;
    dialog = (
      <WarningOkDialog
        modalHeader={Localize.get("MODAL.WARNING")}
        open={showNotAddedItemtWarningModal}
        modalContent={warningNotAddedItemModalMessage}
        onOkFunction={confirmNotAddedItem}
      />
    );
    return dialog;
  };

  const openInfoMsg = () => {
    setShowInfoModal("INFO");
  };

  const closeInfoMsg = () => {
    setShowInfoModal("");
  };

  const getInfoDialog = showInfoModalArg => {
    return (
      <GlobalInfoDialog
        modalHeader={`MODAL.HEADER.${showInfoModalArg}`}
        modalContent={`MODAL.CONTENT.${showInfoModalArg}`}
        onClose={closeInfoMsg}
      />
    );
  };

  // Order items warning message handler add or edit
  const warningOrderItemsMsgHandler = () => {
    let msgArr = [];

    if (docItemMandatoryFields) {
      msgArr.push(Localize.get("MODAL.NOT_ADDED_DOC_ITEM"));
    }

    if (docItemEditFormMandatoryFields) {
      msgArr.push(Localize.get("MODAL.NOT_EDITED_DOC_ITEM"));
    }

    let clearMsg = msgArr.join("");

    setWarningNotAddedItemModalMessage(clearMsg);
    setShowNotAddedItemtWarningModal(true);
  };

  const getFilledDataValidationNotification = () => {
    if (isNotificationAlive) {
      return (
        <FillMandatoryDataWarningNotification
          isAlive={isNotificationAlive}
          setIsNotificationAlive={setIsNotificationAlive}
        />
      );
    }
  };

  const changeItemAmountType = (e, data) => {
    setDocumentZugfredObject({ ...documentZugfredObject, isGross: data.value });
    checkEquality({ ...documentZugfredObject, isGross: data.value });
    if (!editedFields.includes("isGross")) {
      const newArr = [...editedFields];
      newArr.push("isGross");
      setEditedFields(newArr);
    }
  };

  const referedInvoices = () => {
    if (showInvoicesModal) {
      return (
        <GlobalModal
          setShowInvoicesModal={setShowInvoicesModal}
          headerText={"DOCUMENTS.MODAL.HEADER_INVOICE_CORRECTION"}
          CustomComponent={ReferedInvoiceList}
          handleOnChange={handleOnChange}
          showModal={true}
          partner={documentZugfredObject.partner}
          relatedDocument={
            documentZugfredObject.parentDocument && documentZugfredObject.parentDocument.id
              ? documentZugfredObject.parentDocument.id
              : null
          }
          setRelatedDocumentState={setRelatedDocumentState}
          direction={documentZugfredObject.direction}
          showActions={false}
        />
      );
    }
  };

  const confirmPartnerModal = (e, data, value) => {
    handleOnChange(e, { ...data, name: "partner", value });
    setPartnerModal(false);
  };

  const removePartner = (e, data) => {
    handleOnChange(e, { ...data, name: "partner", value: undefined });
  };

  const myModal1 = () => {
    return (
      <div>
        <div>{Localize.get("GLOBAL.INFO.SUBTOTAL_MANDATORY")}</div>
        <Divider />
        <div>{Localize.get("GLOBAL.INFO.SUBTOTAL_FORMAT")}</div>{" "}
      </div>
    );
  };

  const getDocumentNumberField = (docNum, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <Form>
          <FormField required={field.required}>
            <label className={styles.required_label}>
              {Localize.get("DOCUMENTS.FORM.DOCUMENT_NUMBER")}
            </label>
            <Input
              autoComplete={constant.autoComplete.off}
              name="documentNumber"
              value={setDocumentNumberValue()}
              placeholder={docNum ? docNum : Localize.get("DOCUMENTS.FORM.DOCUMENT_NUMBER")}
              onChange={handleOnChange}
              disabled={checkEdit.editMode}
              onBlur={handleOnBlur}
              style={{
                border: documentFormValidator.parameter.documentNumber ? errorBorder : ""
              }}
            />
            {documentFormValidator.parameter.documentNumber && (
              <Label
                basic
                color="red"
                pointing="above"
                content={documentFormValidator.parameter.documentNumber}
                className={styles.zindex}
              />
            )}
          </FormField>
        </Form>
      </Grid.Column>
    );
  };

  const getDirectionField = (myDirection, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <GlobalDropdown
          isRequired={field.required}
          labelName={field.labelName}
          myPlaceholder={field.labelName}
          dropdownName={field.fieldName}
          dropdownValue={myDirection}
          dropdownOptions={expenseOptions}
          handleOnChange={handleOnChange}
          disabled={checkEdit.editMode}
        />
      </Grid.Column>
    );
  };

  const getTypeField = (myType, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <GlobalDropdown
          isRequired={field.required}
          labelName={field.labelName}
          myPlaceholder={field.labelName}
          dropdownName={field.fieldName}
          dropdownValue={myType}
          dropdownOptions={documentTypeOptions()}
          handleOnChange={handleOnChange}
          hasError={documentFormValidator.parameter.documentType}
          disabled={checkEdit.editMode}
        />
      </Grid.Column>
    );
  };

  const handleOnClick = field => {
    switch (field) {
      case "documentDate":
        setCreationDateMenuOpen(true);
        break;
      case "deliveryDate":
        setDeliveryDateMenuOpen(true);
        break;
      case "paymentDue":
        setDueDateMenuOpen(true);
        break;
      default:
        return () => {};
    }
  };

  const handleOnBlurDate = field => {
    switch (field) {
      case "documentDate":
        setCreationDateMenuOpen(false);
        break;
      case "deliveryDate":
        setDeliveryDateMenuOpen(false);
        break;
      case "paymentDue":
        setDueDateMenuOpen(false);
        break;
      default:
        return () => {};
    }
  };

  const getDateOpen = field => {
    switch (field) {
      case "documentDate":
        return creationDateMenuOpen;
      case "deliveryDate":
        return deliveryDateMenuOpen;
      case "paymentDue":
        return dueDateMenuOpen;
      default:
        return false;
    }
  };

  const getDateText = field => {
    switch (field) {
      case "documentDate":
        return getDocumentDate();
      case "deliveryDate":
        return getDeliveryDate();
      case "paymentDue":
        return getPaymentDueDate();
      default:
        return "";
    }
  };

  const getDateDisabledDays = field => {
    switch (field) {
      case "documentDate":
        return [
          {
            after: new Date()
          }
        ];
      default:
        return [];
    }
  };

  const getHandleDateClick = field => {
    switch (field) {
      case "documentDate":
        return handleCreationDateClick;
      case "deliveryDate":
        return handleDeliveryDateClick;
      case "paymentDue":
        return handleDueDateClick;
      default:
        return "";
    }
  };

  const getDocDateField = (myDate, index, field, myRef) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <GlobalDatedown
          isRequired={field.required}
          labelName={field.labelName}
          dropdownName={field.fieldName}
          handleOnClick={() => {
            handleOnClick(field.fieldName);
          }}
          dropdownRef={myRef}
          handleOnBlur={() => handleOnBlurDate(field.fieldName)}
          dropdownOpen={getDateOpen(field.fieldName)}
          hasError={documentFormValidator.parameter[field.fieldName]}
          dropdownText={getDateText(field.fieldName)}
          selectedDays={myDate}
          handleDateClick={getHandleDateClick(field.fieldName)}
          disableDropdown={checkEdit.editMode}
          disabledDays={getDateDisabledDays(field.fieldName)}
          direction={
            field && field.fieldName && field.fieldName === "documentDate" ? "right" : "left"
          }
        />
      </Grid.Column>
    );
  };

  const getPartnerField = (myPartner, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <Form>
          <FormField required={field.required}>
            <label className={styles.required_label}>
              {Localize.get("DOCUMENTS.FORM.PARTNER")}
              {checkPartner && (
                <Icon
                  circular
                  fluid={"right"}
                  name="info"
                  onClick={openParnerMsg}
                  className={styles.partner_info_icon}
                  size="small"
                  color="teal"
                />
              )}
            </label>

            <Form.Group inline style={{ marginTop: "7px", marginBottom: "0px" }}>
              <Popup
                content={Localize.get("CONTACTS.LIST.TOOLBAR.SEARCH_PARTNERS")}
                size="small"
                trigger={
                  <Button
                    floated="left"
                    disabled={checkEdit.editMode}
                    icon="search"
                    onClick={() => setPartnerModal(true)}
                  />
                }
              />

              {myPartner && myPartner.name && myPartner !== "" ? (
                <Input
                  labelPosition="right"
                  floated="right"
                  value={myPartner.name}
                  label={
                    <Popup
                      size="small"
                      content={Localize.get("CONTACTS.LIST.TOOLBAR.REMOVE_PARTNER")}
                      trigger={
                        <Label onClick={removePartner} className={styles.remove_btn_icon}>
                          <Icon name="delete" basic="true" disabled={checkEdit.editMode} />
                        </Label>
                      }
                    />
                  }
                />
              ) : (
                <Input
                  floated="right"
                  disabled={checkEdit.editMode}
                  style={
                    documentFormValidator.parameter.partner
                      ? {
                          border: errorBorder,
                          borderRadius: "5px"
                        }
                      : null
                  }
                  value={Localize.get("DOCUMENTS.FORM.NO_RESULTS")}
                />
              )}
            </Form.Group>
            {documentFormValidator.parameter.partner && (
              <Label
                basic
                color="red"
                pointing="above"
                content={documentFormValidator.parameter.partner}
                className={styles.zindex}
              />
            )}
            {checkPartner && (
              <Label
                className={styles.zindex}
                basic
                color="red"
                pointing="above"
                content={"Partner not exist"}
              />
            )}
          </FormField>
        </Form>
      </Grid.Column>
    );
  };

  const getAddPartner = (index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <Grid className={styles.add_partner_and_invoice_buttons}>
          <Grid.Column width={4} floated="left">
            <FormField inline className={styles.refered_invoice_form_buttons}>
              <AddNewPartner
                isPDFPreview={isPDFPreview}
                linkedDocumentsPreview={linkedDocumentsPreview}
                documentPreview={documentPreview}
                state={state}
                handle={handle}
                showModal={showModal}
                validator={documentFormValidator}
                formErrorMessage={formErrorMessage}
                partnerPrivileges={partnerPrivileges}
                documentDirection={directionValue}
                partnerOCRRead={partnerOCRRead}
                disabled={checkEdit.editMode}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column width={4} floated="right"></Grid.Column>
        </Grid>
      </Grid.Column>
    );
  };

  const getAddPartnerAndSearchInvoice = (myPartner, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <Grid className={styles.add_partner_and_invoice_buttons}>
          <Grid.Column width={4} floated="left">
            <FormField inline className={styles.refered_invoice_form_buttons}>
              <AddNewPartner
                isPDFPreview={isPDFPreview}
                linkedDocumentsPreview={linkedDocumentsPreview}
                documentPreview={documentPreview}
                state={state}
                handle={handle}
                showModal={showModal}
                validator={documentFormValidator}
                formErrorMessage={formErrorMessage}
                partnerPrivileges={partnerPrivileges}
                documentDirection={directionValue}
                partnerOCRRead={partnerOCRRead}
                disabled={checkEdit.editMode}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <FormField inline className={styles.refered_invoice_form_buttons}>
              <Button
                icon
                floated="right"
                primary
                disabled={!myPartner || !myPartner.id || checkEdit.editMode}
                className={styles.refered_invoice_button}
                onClick={() => setShowInvoicesModal(true)}
              >
                <Icon
                  name="search"
                  className={styles.disabled}
                  title={
                    myPartner && myPartner.partner
                      ? Localize.get("DOCUMENTS.FORM.CORRESPONDING_INVOICE")
                      : Localize.get("DOCUMENTS.FORM.RELATED_TO_INVOICE_HOVER")
                  }
                />
              </Button>
            </FormField>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  };

  const getRelatedInvoiceField = (myPartner, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <>
          <Form className={styles.refered_invoice_form}>
            <FormField required className={styles.refered_invoice_form_label}>
              <label className={`${styles.required_label} ${styles.rlated_inv_lable}`}>
                {Localize.get("DOCUMENTS.FORM.RELATED_TO_INVOICE")}
                {myPartner && myPartner.id ? null : (
                  <Icon
                    circular
                    name="info"
                    onClick={openInfoMsg}
                    className={styles.partner_info_icon}
                    size="small"
                    color="teal"
                  />
                )}
              </label>
              <Label
                className={styles.refered_invoice_label}
                basic
                size="large"
                content={
                  relatedDocumentState !== ""
                    ? relatedDocumentState
                    : Localize.get("DOCUMENTS.FORM.NO_INVOICE_LINKED_YET")
                }
                style={{
                  border: documentFormValidator.parameter.parentDocument ? errorBorder : ""
                }}
              />
            </FormField>
          </Form>
          {documentFormValidator.parameter.parentDocument && (
            <div className={styles.error_message_right}>
              <Label
                basic
                color="red"
                pointing="above"
                content={documentFormValidator.parameter.parentDocument}
                className={styles.zindex}
              />
            </div>
          )}
        </>
      </Grid.Column>
    );
  };

  const getCurrencyField = (myCurrency, index, field) => {
    return (
      <Grid.Column key={index} className={styles.inputs_grid_left_padding}>
        <GlobalDropdown
          isRequired={field.required}
          labelName={field.labelName}
          myPlaceholder={field.labelName}
          dropdownName={field.fieldName}
          dropdownValue={myCurrency ? myCurrency.id : ""}
          dropdownOptions={currencyOptions}
          handleOnChange={handleOnChange}
          disabled={checkEdit.editMode}
          className={"icon"}
          hasError={documentFormValidator.parameter.currency}
        />
      </Grid.Column>
    );
  };

  const getRowColumns = (
    configList,
    myDocument,
    docDateDropdown,
    deliveryDateDropdown,
    dueDateDropdown
  ) => {
    return configList.map((field, index) => {
      switch (field.fieldName) {
        case "documentNumber":
          return getDocumentNumberField(myDocument.documentNumber, index, field);
        case "direction":
          return getDirectionField(myDocument.direction, index, field);
        case "documentType":
          return getTypeField(myDocument.documentType, index, field);
        case "documentDate":
          return getDocDateField(myDocument.documentDate, index, field, docDateDropdown);
        case "deliveryDate":
          return getDocDateField(myDocument.deliveryDate, index, field, deliveryDateDropdown);
        case "paymentDue":
          return getDocDateField(myDocument.paymentDue, index, field, dueDateDropdown);
        case "partner":
          return getPartnerField(myDocument.partner, index, field);
        case "addPartner":
          return getAddPartner(index, field);
        case "addPartner&searchInvoice":
          return getAddPartnerAndSearchInvoice(myDocument.partner, index, field);
        case "relatedInvoice":
          return getRelatedInvoiceField(myDocument.partner, index, field);
        case "currency":
          return getCurrencyField(myDocument.currency, index, field);
        default:
          return <div key={index} />;
      }
    });
  };

  const getRows = (
    configRowList,
    myDocument,
    docDateDropdown,
    deliveryDateDropdown,
    dueDateDropdown
  ) => {
    return configRowList.map((column, index) => {
      return (
        <Grid.Row key={index} className={styles.form_row}>
          {getRowColumns(
            column,
            myDocument,
            docDateDropdown,
            deliveryDateDropdown,
            dueDateDropdown
          )}
        </Grid.Row>
      );
    });
  };

  const getNotes = (description = "", { sectionName, labelName, placeholder }) => {
    return (
      <>
        <Form>
          <Form.Field className={styles.input_fields_padding_right}>
            <label className={styles.required_label}>{Localize.get(labelName)}</label>
            <TextArea
              placeholder={Localize.get(placeholder)}
              defaultValue={description}
              onChange={handleOnChange}
              disabled={checkEdit.editMode}
              name={sectionName}
              maxLength={255}
              onBlur={handleOnBlur}
              style={
                documentFormValidator.parameter.description
                  ? {
                      ...noRadiusErrorBorder
                    }
                  : null
              }
            />
            <p className="right floated content">
              {charCounter.charsLeft}
              {"/255"}
            </p>
          </Form.Field>
        </Form>
        {documentFormValidator.parameter.description && (
          <Label basic color="red" pointing content={documentFormValidator.parameter.description} />
        )}
      </>
    );
  };

  const getOrderItems = (myOrderItems, myType, isGross, sectionConfig) => {
    return (
      <>
        <div className={`content ${styles.table_width}`}>
          <DocNewItemTable
            itemLineIdText={"DOCUMENTS.NEWDOCUMENT.DOCUMENT_LINE"}
            sellerItemIdText={"DOCUMENTS.NEWDOCUMENT.SELLER_ITEM_ID"}
            quantityText={"DOCUMENTS.NEWDOCUMENT.QUANTITY"}
            netPriceText={"DOCUMENTS.NEWDOCUMENT.NET_PRICE"}
            discountText={"DOCUMENTS.NEWDOCUMENT.DISCOUNT"}
            surchargeText={"DOCUMENTS.NEWDOCUMENT.SURCHARGE"}
            vatText={"DOCUMENTS.FORM.TAX"}
            itemNameText={"DOCUMENTS.NEWDOCUMENT.NAME"}
            unitText={"DOCUMENTS.NEWDOCUMENT.DOCUMENT_UNIT"}
            items={myOrderItems}
            deleteItem={deleteItem}
            documentType={myType}
            sectionConfig={sectionConfig.configList}
            itemsHandler={itemsHandler}
            checkEdit={checkEdit}
            setCheckEdit={setCheckEdit}
            documentItemsValidator={documentItemsValidator}
            validateOrderItemField={validateOrderItemField}
            amountType={isGross}
            changeItemAmountType={changeItemAmountType}
            updateOrderItemsValidator={updateOrderItemsValidator}
            currency={documentZugfredObject.currency}
          />
        </div>
      </>
    );
  };

  const getSubtotals = myDocument => {
    return (
      <>
        <Header
          className={`${styles.subtotal_header} ${!equalsItemsWithTaxSubtotals &&
            styles.subtotal_header_2}`}
          as="h4"
          dividing
        >
          {Localize.get("DOCUMENTS.NEWDOCUMENT.TAX_SUBTOTALS_HEADER")}
        </Header>
        {!equalsItemsWithTaxSubtotals && (
          <div className={styles.validation_message_red}>
            {Localize.get("DOCUMENTS.FORM.SUBTOTALS_MSG")}
          </div>
        )}

        <TaxSubtotals
          document={myDocument}
          subtotalsHandler={subtotalsHandler}
          checkEdit={checkEdit}
          setCheckEdit={setCheckEdit}
          documentSubtotalsValidator={documentSubtotalsValidator}
          validateSubtotalField={validateSubtotalField}
          validateSubtotalsFunc={validateSubtotalsFunc}
          setSubtotalsAreValid={setSubtotalsAreValid}
        />
        {!subtotalsAreValid && (
          <>
            <Label basic color="red" pointing="above">
              {Localize.get("VALIDATIONMESSAGES.MISSING_MANDATORY_FIELDS")}
              <Popup
                content={Localize.get("GLOBAL.INFO.CHECK_SUBTOTAL_FORMAT")}
                trigger={
                  <Icon
                    className={styles.info_format}
                    circular
                    name="info"
                    color="red"
                    size="small"
                    onClick={() => setRequirementsModal(true)}
                  />
                }
              />
            </Label>
          </>
        )}
      </>
    );
  };

  const getTotals = (myDocument, sectionConfig) => {
    return (
      <DocumentTotal
        document={myDocument}
        documentTotalHandler={documentTotalHandler}
        checkEdit={checkEdit}
        setCheckEdit={setCheckEdit}
        editedFields={editedFields}
        setEditedFields={setEditedFields}
        recalculateClicked={recalculateClicked}
        equalsItemsWithDocumentHeader={equalsItemsWithDocumentHeader}
        equalsTaxSubtotalsWithDocumentHeader={equalsTaxSubtotalsWithDocumentHeader}
        amountType={myDocument.isGross}
        recalculatePerSubtotal={recalculatePerSubtotal}
        totalValidator={totalValidator}
        validateTotalField={validateTotalField}
        sectionConfig={sectionConfig.configList}
        setTotalIsValid={setTotalIsValid}
      />
    );
  };

  const visibleFieldsForm = visibleFields.find(el => el.sectionName === "form");
  const visibleFieldsDescription = visibleFields.find(el => el.sectionName === "description");
  const visibleFieldsItems = visibleFields.find(el => el.sectionName === "orderItems");
  const visibleFieldsTotals = visibleFields.find(el => el.sectionName === "total");

  const panesByType = () => {
    let panes;
    switch (documentZugfredObject.documentType) {
      case "delivery":
        panes = [
          {
            menuItem: (
              <Menu.Item
                key={0}
                disabled={checkEdit.editMode}
                className={!docIsValid ? styles.error_border : null}
              >
                {Localize.get("DOCUMENTS.FORM.GENERAL")}
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                <Grid
                  className={styles.input_fields_padding}
                  columns={visibleFieldsForm.numberOfColumns}
                >
                  {getRows(
                    visibleFieldsForm.configList,
                    documentZugfredObject,
                    docDateDropdown,
                    deliveryDateDropdown,
                    dueDateDropdown
                  )}
                </Grid>
                {getNotes(documentZugfredObject.description, visibleFieldsDescription)}
              </Tab.Pane>
            )
          },
          {
            menuItem: (
              <Menu.Item key={1} disabled={checkEdit.editMode}>
                {Localize.get("DOCUMENTS.FORM.ITEMS")}
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                {getOrderItems(
                  documentZugfredObject.orderItems,
                  documentZugfredObject.documentType,
                  documentZugfredObject.isGross,
                  visibleFieldsItems
                )}
              </Tab.Pane>
            )
          }
        ];
        break;

      case "inquiry":
        panes = [
          {
            menuItem: Localize.get("DOCUMENTS.FORM.GENERAL"),
            render: () => (
              <Tab.Pane attached={false}>
                <Grid
                  className={styles.input_fields_padding}
                  columns={visibleFieldsForm.numberOfColumns}
                >
                  {getRows(
                    visibleFieldsForm.configList,
                    documentZugfredObject,
                    docDateDropdown,
                    deliveryDateDropdown,
                    dueDateDropdown
                  )}
                </Grid>
                {getNotes(documentZugfredObject.description, visibleFieldsDescription)}
              </Tab.Pane>
            )
          }
        ];
        break;
      case "":
        panes = [
          {
            menuItem: Localize.get("DOCUMENTS.FORM.GENERAL"),
            render: () => (
              <Tab.Pane attached={false}>
                <Grid
                  className={styles.input_fields_padding}
                  columns={visibleFieldsForm.numberOfColumns}
                >
                  {getRows(
                    visibleFieldsForm.configList,
                    documentZugfredObject,
                    docDateDropdown,
                    deliveryDateDropdown,
                    dueDateDropdown
                  )}
                </Grid>
                {getNotes(documentZugfredObject.description, visibleFieldsDescription)}
              </Tab.Pane>
            )
          }
        ];
        break;
      default:
        panes = [
          {
            menuItem: (
              <Menu.Item
                key={0}
                disabled={checkEdit.editMode}
                className={!docIsValid ? styles.error_border : null}
              >
                {Localize.get("DOCUMENTS.FORM.GENERAL")}
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                <Grid
                  className={styles.input_fields_padding}
                  columns={visibleFieldsForm.numberOfColumns}
                >
                  {getRows(
                    visibleFieldsForm.configList,
                    documentZugfredObject,
                    docDateDropdown,
                    deliveryDateDropdown,
                    dueDateDropdown
                  )}
                </Grid>
                {getNotes(documentZugfredObject.description, visibleFieldsDescription)}
              </Tab.Pane>
            )
          },
          {
            menuItem: (
              <Menu.Item key={1} disabled={checkEdit.editMode}>
                {Localize.get("DOCUMENTS.FORM.ITEMS")}
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                {getOrderItems(
                  documentZugfredObject.orderItems,
                  documentZugfredObject.documentType,
                  documentZugfredObject.isGross,
                  visibleFieldsItems
                )}
              </Tab.Pane>
            )
          },
          {
            menuItem: (
              <Menu.Item
                disabled
                key={2}
                className={!totalIsValid || !subtotalsAreValid ? styles.error_border : null}
              >
                {Localize.get("DOCUMENTS.FORM.SUBTOTALS")}
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                {getSubtotals(documentZugfredObject)}
                {getTotals(documentZugfredObject, visibleFieldsTotals)}
              </Tab.Pane>
            )
          }
        ];
        break;
    }
    return panes;
  };

  return (
    <>
      {referedInvoices()}
      {getFilledDataValidationNotification()}
      {getWarningDialog()}
      {getNotAddedItemWarningDialog()}
      {showInfoModal !== "" && getInfoDialog(showInfoModal)}
      {registertedPartnerData()}

      <div className={styles.bms_header}>
        {Localize.get(
          state.panelMode === constant.panelMode.edit
            ? "DOCUMENTS.FORM.HEADER_FORM_EDIT"
            : "DOCUMENTS.FORM.HEADER_FORM"
        )}
      </div>

      <Dimmer active={isLoadingForm || partnersLoading} inverted>
        <Loader size="large" disabled={!isLoadingForm && !partnersLoading}>
          {isLoadingForm && Localize.get("DOCUMENTS.FORM.DOCUMENT_LOADER")}
          {partnersLoading && "Checking for partner"}
        </Loader>
      </Dimmer>

      <div className={styles.bms_form_container}>
        {/* Form start */}
        <Tab menu={{ secondary: true, pointing: true }} panes={panesByType()} />
        {/* Form end */}
      </div>
      {requirementsModal && (
        <GlobalModal
          setShowModal={setRequirementsModal}
          headerText={"GLOBAL.INFO.SUBTOTAL_FORMAT_HEADER"}
          showModal={requirementsModal}
          CustomComponent={myModal1}
        />
      )}
      {partnerModal && (
        <GlobalModal
          setShowModal={setPartnerModal}
          modalHandler={confirmPartnerModal}
          showModal={partnerModal}
          CustomComponent={ReferedPartner}
          documentZugfredObject={documentZugfredObject}
          handleOnChange={handleOnChange}
          size="large"
          customClassName={styles.partner_modal}
          checkPartner={false}
          headerText={"CONTACTS.LIST.TOOLBAR.SELECT_PARTNER"}
          showActions={false}
          partnerValue={partnerValue}
        />
      )}

      <GlobalSaveNewCancelBtn
        editMode={state.panelMode === constant.panelMode.edit}
        onUpdate={onUpdateDocumentSave}
        onSave={onSaveClick}
        onSaveNew={onSaveNewClick}
        onCancel={handler.documentForm.onCancelButtonClick}
        disabledBtn={checkEdit.editMode}
      />
    </>
  );
};

export default DocumentForm;
