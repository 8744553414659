import React, { useState, useEffect, useContext, useRef } from "react";
import { DateUtils } from "react-day-picker";
import { Container } from "semantic-ui-react";
import { Dropdown, Button, Segment, Divider, Modal, Input } from "semantic-ui-react";
import get from "lodash/get";
import { format } from "date-fns";

import "react-day-picker/lib/style.css";
import Localize from "react-intl-universal";
import * as RestService from "../RestService";
import { formatDateToString } from "./Util";
import GlobalList from "../Components/Lists/GlobalList";
import { AuthContext } from "../Auth/AuthContext";
import styles from "./style/ExportDatev.module.css";
import DatepickerWithDropdown from "../Documents/RightPanel/DatepickerWithDropdown";
import * as constants from "./Constants";
import GlobalOkCancelBtn from "../Components/GlobalOkCancelBtn";
import GlobalOkBtn from "../Components/GlobalOkBtn";
import { progressStatus } from "../Documents/Constants";

const Datev = props => {
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const { authState, rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["datevExport"];

  const [exportDateRange, setExportDateRange] = useState({
    from: DateUtils.addMonths(new Date(), -1),
    to: new Date()
  });
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const [dateRangeErr, setDateRangeErr] = useState(false);
  const [errorMessageArr, setErrorMessageArr] = useState([]);
  const [customErrorMessage, setCustomErrorMessage] = useState("");

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /*Alert if clicked on outside of element*/
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref.current === datepickerDropdown.current) {
            setDateMenuOpen(false);
          }
        }
      }

      // Bind the event listener
      window.document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        window.document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const datepickerDropdown = useRef(null);
  useOutsideAlerter(datepickerDropdown);

  const currentLanguage = navigator.language.toLowerCase().includes("de") ? "de" : "en";

  const [openModal, setOpenModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [documentsToExportIds, setDocumentsToExportIds] = useState([]);
  // eslint-disable-next-line no-unused-vars

  const callbackGetAllSuccess = response => {
    const ids = response.data.data.map(document => document.id);
    setDocumentsToExportIds(ids);
    let msg = "";
    let confirmDialog = false;

    if (response.data.data.length > 0) {
      const documents = response.data.data;
      if (!response.data.data) {
        msg = Localize.get("EXPORT.DATEV.MODAL_QUESTION_CANT_EXPORT");
      } else {
        msg = Localize.get("EXPORT.DATEV.MODAL_QUESTION_CAN_EXPORT", {
          docCount: documents.length
        });
        confirmDialog = true;
      }
    }

    if (response.data.data.length === 0) {
      msg = Localize.get("EXPORT.DATEV.MODAL_QUESTION_NO_EXPORT");
    }

    setConfirmMessage(msg);
    setIsConfirmDialog(confirmDialog);
  };
  const callbackExportSuccess = async response => {
    const now = new Date();
    const formattedDateTime = format(now, "yyyyMMddHHmmss");

    let blob = new Blob([response.data], {
      type: "application/zip"
    });
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    // a.download = responseFileName[0];
    a.download = `download_${formattedDateTime}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

    history.push("/exports/exported");
  };

  const callbackGetAllError = () => {};

  const callbackExportError = err => {
    const error = get(err, "response.data", null);
    try {
      error &&
        error.text().then(text => {
          const errorMessage = JSON.parse(text);

          if (errorMessage) {
            setOpenModal(true);
            setIsConfirmDialog(false);
            setErrorMessageArr(errorMessage.validationChildren);
          }
        });
    } catch (error) {
      console.error(error);
    }

    if (error && error.message === "{EXPORT.DATEV.MSG_E_DATEV_NOT_VALID}") {
      setCustomErrorMessage(Localize.get("EXPORT.DATEV.MSG_E_DATEV_NOT_VALID"));
    } else if (error && error.message === constants.notFound) {
      setOpenModal(true);
      setIsConfirmDialog(false);
      setConfirmMessage(Localize.get("EXPORT.DATEV.NOT_FOUND"));
    }
  };

  const callbackGetAllFinally = () => {};

  const handlerOpenModal = () => {
    setOpenModal(!openModal);
    setErrorMessageArr([]);
  };

  const handleTransver = () => {
    setOpenModal(false);
    setConfirmMessage("");
    setErrorMessageArr([]);

    const documentsExport = new RestService.Datev.Export.Service();
    documentsExport.setCallbackSuccess(callbackExportSuccess);
    documentsExport.setCallbackError(callbackExportError);
    documentsExport.setCallbackFinally(callbackGetAllFinally);
    documentsExport.query.addResponseType("blob");

    documentsExport.query.addDocumentIds(documentsToExportIds);
    documentsExport.query.clearPageSize();
    documentsExport.query.clearPage();
    documentsExport.query.addDocumentFromDate(formatDateToString(exportDateRange.from));
    documentsExport.query.addDocumentToDate(formatDateToString(exportDateRange.to));

    documentsExport.call();
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const documentsGetAll = new RestService.Document.GetAll.Service();
    documentsGetAll.setCallbackSuccess(callbackGetAllSuccess);
    documentsGetAll.setCallbackError(callbackGetAllError);
    documentsGetAll.setCallbackFinally(callbackGetAllFinally);
    documentsGetAll.query.clearPageSize();
    documentsGetAll.query.clearPage();
    documentsGetAll.query.addDocumentTypeinvoiceAndCorrection();
    documentsGetAll.query.addSentToDatev(false);
    documentsGetAll.query.progressStatus(progressStatus.pdfCreated);
    documentsGetAll.query.addDocumentDirectionOutgoing();
    documentsGetAll.query.addDocumentToDate(formatDateToString(exportDateRange.to));
    documentsGetAll.query.addDocumentFromDate(formatDateToString(exportDateRange.from));

    await documentsGetAll.call();

    handlerOpenModal();
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  const trigger = (
    <span>
      <Input
        className={styles.datepicker_input}
        icon="calendar alternate outline"
        readOnly
        id="fromDate"
        name="fromDate"
        type="text"
        placeholder="Date"
        value={
          exportDateRange.from.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }) +
          " - " +
          exportDateRange.to.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
        }
      />
    </span>
  );

  const closeDateMenu = () => {
    setDateMenuOpen(false);
  };

  return (
    <>
      <Container text>
        <Divider hidden={true} />
        <h2>{Localize.get("EXPORT.DATEV.EXPORT_HEADER")}</h2>
        <div
        // onSubmit={handleSubmit}
        >
          <Segment className={styles.center_div}>
            <div ref={datepickerDropdown}>
              <h4 className={styles.header}>{Localize.get("EXPORT.DATEV.EXPORT_CHOOSE")}</h4>
              {dateRangeErr === true ? <p>{Localize.get("EXPORT.DATEV.EXPORT_ERROR")}</p> : null}

              <Dropdown
                trigger={trigger}
                floating
                labeled
                button
                onClick={() => setDateMenuOpen(true)}
                onBlur={closeDateMenu}
                closeOnBlur
                icon={null}
              >
                <Dropdown.Menu open={dateMenuOpen}>
                  <DatepickerWithDropdown
                    numberOfMonths={2}
                    setExportDateRange={setExportDateRange}
                    setDateRangeErr={setDateRangeErr}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Divider hidden={true} className={styles.divider_margin} />
              <Button
                color="blue"
                floated="right"
                type="submit"
                disabled={!privileges.create || dateRangeErr}
                onClick={handleSubmit}
              >
                {Localize.get("EXPORT.DATEV.EXPORT_START")}
              </Button>
            </div>
          </Segment>
        </div>
      </Container>
      <Modal size="small" open={openModal} dimmer="inverted">
        <Modal.Header className={styles.modal_header}>
          {Localize.get("EXPORT.DATEV.EXPORT_FILES")}
        </Modal.Header>
        <Modal.Content>
          <div>
            {isConfirmDialog ? (
              <p>
                {confirmMessage}
                <span className={styles.span_style}>
                  {exportDateRange.from.toLocaleDateString(currentLanguage, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })}
                </span>
                -{" "}
                <span className={styles.span_style}>
                  {exportDateRange.to.toLocaleDateString(currentLanguage, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })}{" "}
                </span>{" "}
                ?
              </p>
            ) : (
              <>
                <p>{confirmMessage ? confirmMessage : null}</p>
                {errorMessageArr && errorMessageArr.length !== 0 && (
                  <GlobalList
                    availableItemFieldsForList={constants.availableItemFieldsForListProperties}
                    listState={errorMessageArr}
                  />
                )}
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          {isConfirmDialog ? (
            <GlobalOkCancelBtn
              onConfirmClicked={handleTransver}
              onCancel={handlerOpenModal}
              btnText={Localize.get("GLOBAL.CONFIRMATION.BUTTON_YES")}
            />
          ) : (
            <GlobalOkBtn onOkClicked={handlerOpenModal} />
          )}
        </Modal.Actions>
      </Modal>
      <Modal size="small" open={customErrorMessage === "" ? false : true} dimmer="inverted">
        <Modal.Header className={styles.modal_header}>
          customErrorMessage !== "" ?{Localize.get("EXPORT.DATEV.EXPORT_FILES")} :
          {Localize.get("EXPORT.DATEV.ERROR")}
        </Modal.Header>
        <Modal.Content>{customErrorMessage}</Modal.Content>
        <Modal.Actions>
          <GlobalOkBtn onOkClicked={() => setCustomErrorMessage("")} />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default Datev;
