import React, { useState, useEffect, useRef } from "react";
import Localize from "react-intl-universal";
import { Form, Checkbox, Button, Icon, Input, Popup, Dropdown } from "semantic-ui-react";
import * as constant from "../Constants.js";
import styles from "./style/DocumentsToolBar.module.css";
import GlobalModal from "../../Components/Panels/GlobalModal.js";
import ToolbarDatepicker from "../../Components/Inputs/ToolbarDatepicker.js";
import { DateUtils } from "react-day-picker";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

const SEND = "send";
const DOWNLOAD = "download";

const DocumentsToolBar = props => {
  const {
    handler,
    checkedDocs,
    dateTypeValue,
    state,
    privileges,
    paymentPrivileges,
    resetSearches,
    resetNameSearch,
    searchName,
    enteredValue,
    selectAll,
    onToggleAll,
    onArchiveClick,
    onDeleteClick,
    emptyList,
    // deleteEnable,  ---- TO DO Check, disabled for FFO
    customStyle,
    popupContentText,
    disableSearch,
    // setQuickInvoicingModal, ---to do--
    // quickInvoicingModal, ---to do--
    setPayMode,
    respDownAndSendMessage,
    setActiveItem
    // unverifiedPartners  ---- TO DO Check, disabled for FFO
  } = props;

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [selectedAction, setSelectedAction] = useState();
  const [dateRangefromPicker, setDateRangeFromPicker] = useState({
    from: DateUtils.addMonths(new Date(), -1),
    to: new Date()
  });

  const history = useHistory();

  const handleModalOpen = action => {
    setShowDownloadModal(true);
    setSelectedAction(action);
  };

  const dateRangeOptions = [
    {
      key: 0,
      value: "paymentDate",
      text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.PAYMENT_DATE"),
      disabled: state.activeDueStatusFilter !== constant.documentDue.all
    },
    {
      key: 1,
      value: "documentDate",
      text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.DOCUMENT_DATE")
    },
    {
      key: 2,
      value: "createdAt",
      text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.CREATION_DATE")
    },
    {
      key: 3,
      value: "resetDates",
      text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.RESET")
    }
  ];

  const handleOnChange = (e, data) => {
    handler.onDateTypeChange(data.value, dateRangefromPicker);
  };

  const payButtonEnable = () => {
    if (!paymentPrivileges.create) {
      return false;
    }

    let approveStaus;
    checkedDocs.length > 0
      ? (approveStaus = checkedDocs.every(chekedItem =>
          chekedItem.actions && chekedItem.actions.PaymentRules
            ? chekedItem.actions.PaymentRules.allowFinapiPayment
            : null
        ))
      : (approveStaus = false);

    return approveStaus;
  };

  const importedDocSelected =
    checkedDocs.length > 0
      ? checkedDocs.some(doc => doc.documentType === constant.documentType.imported_invoice)
      : false;

  const disableArchive = () =>
    checkedDocs.length > 0
      ? checkedDocs.some(
          doc =>
            doc.documentType === constant.documentType.invoice &&
            doc.progressStatus === constant.progressStatus.pdfCreated
        )
      : false;

  const disableDelete = () =>
    checkedDocs.length > 0
      ? checkedDocs.some(doc => doc.progressStatus !== constant.progressStatus.imported)
      : false;

  const disableItem = () => {
    //----TO DO unverifiedPartners is not correct ----
    // const isUnverifiedPartner = get(unverifiedPartners, "count") > 0;
    return checkedDocs.length === 0 ? checkedDocs.length === 0 : importedDocSelected;
  };

  const allDocsHavePartner = checkedDocs.length > 0 ? checkedDocs.every(doc => doc.partner) : false;

  const handleModalConfirm = () => {
    setShowDownloadModal(false);

    switch (true) {
      case allDocsHavePartner && selectedAction === SEND:
        return handler.documentList.onMultipleSendPDFClick(checkedDocs.map(doc => doc.id));
      case !allDocsHavePartner && selectedAction === SEND:
        return () => {};
      default:
        return handler.documentList.onMultipleDownloadPDFClick(checkedDocs.map(doc => doc.id));
    }
  };

  const modalCustom = () => {
    switch (selectedAction) {
      case DOWNLOAD:
        let numberOfDoc = checkedDocs.length;
        let question =
          Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.DOWNLOAD_QUESTION") +
          " " +
          numberOfDoc +
          " " +
          (numberOfDoc === 1
            ? Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.INVOICE_QUESTION")
            : Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.INVOICES_QUESTION"));

        return question;
      case SEND:
        return allDocsHavePartner
          ? Localize.get("DOCUMENTS.MODAL.ARE_YOU_SURE")
          : Localize.get("DOCUMENTS.MODAL.SELECT_PARTNERS");
      default:
        return "Modal";
    }
  };

  let templateError = (
    <>
      {selectedAction === SEND ? (
        <div>{Localize.get("DOCUMENTS.MODAL.MODAL_SEND_ERROR")}</div>
      ) : (
        <div>{Localize.get("DOCUMENTS.MODAL.MODAL_GENERATE_ERROR")}</div>
      )}
      <Button
        icon
        labelPosition="right"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          setActiveItem("settings");
          history.push({
            pathname: "/settings",
            state: {
              routeModule: "categories"
            }
          });
        }}
      >
        {Localize.get("DOCUMENTS.MODAL.GENERATE_TEMPLATE")}
        <Icon name="right arrow" />
      </Button>
    </>
  );

  const modalResponseCustom = () => {
    switch (get(respDownAndSendMessage, "status")) {
      case 200:
        return get(respDownAndSendMessage, "data");
      case 404:
        return templateError;
      case 400:
        return get(respDownAndSendMessage, "data.message");
      default:
        return "Error";
    }
  };

  const dateChangeHandler = newDateRange => {
    setDateRangeFromPicker(newDateRange);
    handler.onDateToolbarChange(undefined, {
      from: newDateRange.from,
      to: newDateRange.to
    });
  };

  const isInitialMount = useRef(true);
  //useEffect skip setting on the first render
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setResponseModal(true);
    }
  }, [respDownAndSendMessage]);

  return (
    <>
      <GlobalModal
        showModal={showDownloadModal}
        setShowModal={setShowDownloadModal}
        modalHandler={handleModalConfirm}
        headerText={
          selectedAction === DOWNLOAD
            ? "DOCUMENTS.ITEMLIST.TOOLBAR.DOWNLOAD_PDF"
            : "DOCUMENTS.MODAL.SENDING_MAIL"
        }
        CustomComponent={modalCustom}
        customClassName={styles.download_header}
        showCancel={!(!allDocsHavePartner && selectedAction === SEND)}
      />

      <GlobalModal
        showModal={responseModal}
        setShowModal={setResponseModal}
        modalHandler={() => setResponseModal(false)}
        headerText={
          get(respDownAndSendMessage, "status") === 200
            ? "DOCUMENTS.MODAL.MODAL_SUCCESS"
            : "DOCUMENTS.MODAL.MODAL_ERROR"
        }
        CustomComponent={modalResponseCustom}
        customClassName={
          get(respDownAndSendMessage, "status") === 200 ? styles.download_header : styles.error
        }
        showCancel={false}
      />
      <Form className={`${styles.toolBarForm} ${customStyle}`}>
        <Checkbox
          label={Localize.get("CONTACTS.LIST.TOOLBAR.ALL")}
          className={styles.checkboxAll}
          checked={selectAll}
          onChange={onToggleAll}
          disabled={emptyList}
        />
        <Button
          basic
          className={styles.toolbar_button}
          onClick={resetSearches}
          disabled={disableReset}
        >
          {Localize.get("CONTACTS.LIST.TOOLBAR.RESET")}
        </Button>
        <Popup
          content={
            popupContentText
              ? popupContentText
              : Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PLACEHOLDER")
          }
          on={["hover", "click"]}
          trigger={
            <Input
              disabled={disableSearch ? disableSearch : false}
              icon={
                enteredValue ? (
                  <Icon name="close" onClick={resetNameSearch} circular link size="small" />
                ) : (
                  <Icon name="search" />
                )
              }
              className={styles.header_search}
              name="search"
              onChange={searchName}
              autoComplete={"off"}
              value={enteredValue ? enteredValue : ""}
              type="text"
              placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
            />
          }
        />
      </Form>

      <Dropdown
        icon="dropdown"
        noResultsMessage={Localize.get("DOCUMENTS.FORM.NO_RESULTS")}
        placeholder={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.DATE_TYPE")}
        name="dateType"
        className={styles.date_type}
        selection
        options={dateRangeOptions}
        value={dateTypeValue}
        onChange={handleOnChange}
        closeOnChange
      />
      <ToolbarDatepicker
        disabledDropdown={
          dateTypeValue === constant.toolbarDayPicker.documentDate ||
          dateTypeValue === constant.toolbarDayPicker.paymentDate ||
          dateTypeValue === constant.toolbarDayPicker.createdAt
            ? false
            : true
        }
        dateChangeHandler={dateChangeHandler}
        setDisableReset={setDisableReset}
      />
      <Dropdown
        text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.ACTIONS")}
        button
        className={styles.headerActions}
      >
        <Dropdown.Menu className={styles.dropdowmMenu}>
          {state.activeDocumentDirectionFilter === constant.documentDirection.incoming ? (
            <Dropdown.Item
              name="pay"
              icon="euro"
              description={
                <Icon
                  color={payButtonEnable() ? "green" : "grey"}
                  name="circle thin"
                  className={`${"right floated"} ${styles.green_icon}`}
                />
              }
              disabled={!payButtonEnable()}
              text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.PAY")}
              onClick={setPayMode}
            />
          ) : (
            <>
              {disableItem() && checkedDocs.length > 0 && (
                <Dropdown.Item
                  icon="angle double right"
                  text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.VERIFY_PARTNERS")}
                  onClick={handler.documentList.onVerifyPartnersClick}
                />
              )}
              <Dropdown.Item
                icon="sitemap"
                text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.PROCESS")}
                disabled={emptyList}
                onClick={handler.documentList.onProcessClick}
              />
              <Dropdown.Item
                icon="file pdf outline"
                text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.GENERATE_PDF")}
                disabled={disableItem()}
                onClick={handler.documentList.onMultipleMakePDFClick}
              />
              <Dropdown.Item
                disabled={disableItem()}
                icon="download"
                text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.DOWNLOAD_PDF")}
                onClick={() => handleModalOpen(DOWNLOAD)}
              />
              <Dropdown.Item
                icon="mail outline"
                disabled={disableItem()}
                text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.SEND_PDF")}
                onClick={() => handleModalOpen(SEND)}
              />
              <Dropdown.Item
                icon="file alternate outline"
                text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.INVOICE_CORRECTION")}
                disabled={true}
                //----TO DO ---/
                // disabled={checkedDocs.length === 0}
                onClick={handler.documentList.onMultipleCorrectionClick}
              />
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item
            name="archive"
            icon="archive"
            disabled={
              !privileges.update || checkedDocs.length === 0 || emptyList || disableArchive()
            }
            text={
              state.activeArchiveFilter !== "archived"
                ? Localize.get("CONTACTS.LIST.TOOLBAR.ARCHIVE")
                : Localize.get("CONTACTS.LIST.TOOLBAR.UNARCHIVE")
            }
            onClick={onArchiveClick}
          />
          <Dropdown.Item
            name="delete"
            icon="trash alternate"
            disabled={checkedDocs.length === 0 || disableDelete()}
            text={Localize.get("CONTACTS.LIST.TOOLBAR.DELETE")}
            onClick={onDeleteClick}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DocumentsToolBar;
