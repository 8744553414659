import React, { useState } from "react";
import { Menu, Icon, Sidebar, Button, Container, Dimmer, Loader } from "semantic-ui-react";
import * as constant from "../Constants.js";
import Localize from "react-intl-universal";
import styles from "./style/SideBar.module.css";
import OpenFilterModule from "./OpenFilterModule";
import GlobalModal from "../../Components/Panels/GlobalModal.js";
import useDocumentsServices from "../../RestServicesNew/useDocumentsServices.js";
import { BASE } from "../../RestService/Constants.js";

const SideBar = props => {
  const {
    privileges,
    sideBarVisibility,
    setSideBarVisibility,
    documentsPanelState,
    fetchData
  } = props;

  const { syncFFO, syncFFOLoading } = useDocumentsServices(fetchData);

  const [showSinhroModal, setShowSinhroModal] = useState(false);

  const onButtonDropdown = (e, data) => {
    setShowSinhroModal(true);
  };

  const makeFFOCall = async () => {
    setShowSinhroModal(false);
    await syncFFO({}, BASE.resource.syncWithFFO, null, null, null, { ignoreInvalid: true });
  };

  const modalMessageComponent = () => (
    <div>{Localize.get("DOCUMENTS.SIDEBAR.CONFIRM_SYNCHRONIZE_MESSAGE")}</div>
  );

  return (
    <>
      <Dimmer active={syncFFOLoading} inverted>
        <Loader className={styles.loader_center} size="large" disabled={!syncFFOLoading}>
          {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENTS_LOADING")}
        </Loader>
      </Dimmer>

      <GlobalModal
        showModal={showSinhroModal}
        setShowModal={setShowSinhroModal}
        headerText={"DOCUMENTS.SIDEBAR.CONFIRM_SYNCHRONIZE"}
        CustomComponent={modalMessageComponent}
        modalHandler={makeFFOCall}
      />
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Container>
          {documentsPanelState.activeDocumentDirectionFilter ===
          constant.documentDirection.incoming ? (
            <Button
              primary
              disabled={!privileges.create}
              className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
              onClick={props.handler.sidebar.onNewDocumentButtonClick}
            >
              {Localize.get("DOCUMENTS.SIDEBAR.NEW_DOCUMENT")}
              <Icon name="plus" />
            </Button>
          ) : (
            <Button
              primary
              className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
              onClick={onButtonDropdown}
            >
              {Localize.get("DOCUMENTS.SIDEBAR.SYNC").toUpperCase()}
              <Icon name="sync alternate" />
            </Button>
          )}
        </Container>

        <OpenFilterModule
          filter={constant.sideBarDirectionConfig}
          onFilterClick={props.handler.sidebar.onDocumentDirectionFilterClick}
          activeFilter={documentsPanelState.activeDocumentDirectionFilter}
        />
        {documentsPanelState.activeDocumentDirectionFilter ===
        constant.documentDirection.incoming ? (
          <>
            <OpenFilterModule
              filter={constant.sideBarVerificationConfig}
              onFilterClick={props.handler.sidebar.onVerificationStatusFilterClick}
              activeFilter={documentsPanelState.activeVerificationStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarApprovalConfig}
              onFilterClick={props.handler.sidebar.onPaymentStatusFilterClick}
              activeFilter={documentsPanelState.activePaymentStatusFilter}
            />
          </>
        ) : (
          <>
            <OpenFilterModule
              filter={constant.sideBarDocumentType}
              onFilterClick={props.handler.sidebar.onDocumentTypeFilterClick}
              activeFilter={documentsPanelState.activeDocumentTypeFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarVerificationConfig}
              onFilterClick={props.handler.sidebar.onVerificationStatusFilterClick}
              activeFilter={documentsPanelState.activeVerificationStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarPayStatusConfig}
              onFilterClick={props.handler.sidebar.onPaymentRevenueStatusFilterClick}
              activeFilter={documentsPanelState.activeRevenuePaymentStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarStatus}
              onFilterClick={props.handler.sidebar.onProgressStatusFilterClick}
              activeFilter={documentsPanelState.activeProgressStatusFilter}
            />
          </>
        )}
        <Menu.Item>
          <Menu.Menu className={styles.active_menu_item}>
            <Menu.Item
              name={constant.archive.archived}
              onClick={props.handler.sidebar.onArchiveFilterClick}
              active={documentsPanelState.activeArchiveFilter === constant.archive.archived}
            >
              <Icon name="archive" /> {Localize.get("CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default SideBar;
